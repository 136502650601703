import { useIntl } from 'react-intl';
import { useState, useEffect } from 'react';
import { message, theme } from 'antd';

export const useReactPath = () => {
  const [path, setPath] = useState(window.location.pathname)
  const listenToPopstate = () => {
    const winPath = window.location.pathname
    setPath(winPath)
  };
  useEffect(() => {
    window.addEventListener("popstate", listenToPopstate)
    return () => {
      window.removeEventListener("popstate", listenToPopstate)
    }
  }, [])
  return path
};

/**
 *  @param {id} Message id
 *  @param {values} Values object of the formatted message
 */
export const useFormatMessage = () => {
  const { formatMessage } = useIntl()
  return (id, values) => formatMessage({ id }, values)
}

/**
 * Translates errorCode from API
 * logic: 
 ** if there is a translatable errorCode, there should be no errorMessage from the server
 ** if there is an errorMessage, show it first, because there is no translatable error code
 *  @param {response} object (error.response) 
 */
const translateApiError = (response, f, name = 'this request') => {
  if (!response) return f('Error.Unknown')
  if (response.status === 403) return `Permissions missing for ${name}`
  if (response.data?.errorMessage) return response.data.errorMessage
  if (response.data?.errorCode) return f(response.data.errorCode)
  return `${f('Error')}: ${response.status} ${response.statusText}`
}

export const useHandleApiError = () => {
  const f = useFormatMessage()

  return (error, name = 'this request') => {
    const { response } = error

    if (!response) {
      if (process.env.NODE_ENV === 'development') {
        console.log("coding error", error)
      }
    }

    if (response?.status === 401) {
      //we do not return an error here because it is (usually) transient
      console.log("User is not authenticated")
      return
    }

    const text = translateApiError(response, f, name)
    message.error(text)
    if (process.env.NODE_ENV === 'development')
      console.log(text)
  }
}

/** return current Ant design theme - 0: light, 1: dark */
export const useTheme = () => {
  const token = theme.useToken()
  return token.theme.id === 0 ? 'light' : 'dark'
}
