import dayjs from 'dayjs';
import { upperFirst } from 'lodash'
import { FormattedMessage } from 'react-intl';

import { PERMISSION, translations } from '../../Constants';
import { dateComparison, stringComparison, checkPermission, boolComparison } from './utils';
import { useFormatMessage } from './hooks'

const dateFormat = 'DD-MM-YYYY HH:mm';

const dataIndexToKey = dataIndex => Array.isArray(dataIndex) ? dataIndex.join(".") : dataIndex

const baselineColumns = [
  "createDate",
  "location",
  "lastMessageSent",
  "currentState",
]

/**
 * useColumns hook for delivery tables. all params are optional.
 * @param permissions array: user permissions
 * @param allowedColumns map: defines what columns are used and required
 * @param baseColumns array: columns that are always used
 */
export const useColumns = (permissions = [], allowedColumns = new Map(), baseColumns = baselineColumns) => {
  const f = useFormatMessage()

  const columns = [
    {
      // title creates BUG using f() instead of FormattedMessage: https://my.origo.is/browse/ZMIDSD-262
      title: <FormattedMessage id="ActionDeliveries.TH.Recipient" />,
      dataIndex: ['recipient', 'name'],
      sorter: (a, b) =>
        stringComparison(a.recipient, b.recipient, recipient => recipient.name),
      sortDirections: ['ascend', 'descend']
    },
    {
      title: <FormattedMessage id="ActionDeliveries.TH.Kennitala" />,
      dataIndex: ['recipient', 'identificationNumber'],
      sorter: (a, b) =>
        stringComparison(a.recipient, b.recipient, recipient => recipient.identificationNumber),
      sortDirections: ['ascend', 'descend']
    },
    {
      title: <FormattedMessage id="ActionDeliveries.TH.RentalCar" />,
      dataIndex: 'rentalCar',
      sorter: (a, b) => boolComparison(a.rentalCar, b.rentalCar),
      sortDirections: ['ascend', 'descend'],
      render: rentalCar => (rentalCar ? 'X' : ''),
    },
    {
      title: <FormattedMessage id="ActionDeliveries.TH.OrderType" />,
      dataIndex: 'orderType',
      render: orderType => (orderType ? upperFirst(orderType) : ''),
      sorter: (a, b) => stringComparison(a.orderType, b.orderType, orderType => orderType),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: <FormattedMessage id="CreateCollection.OrderNumber" />,
      dataIndex: 'senderOrderId',
      sorter: (a, b) => stringComparison(a.senderOrderId, b.senderOrderId),
      sortDirections: ['ascend', 'descend'],
      // render: num => <Link to={`/about-delivery/${num}`} className="btn-link">{num}</Link>,
      width: 140,
    },
    {
      title: <FormattedMessage id="ActionDeliveries.TH.BusinessKey" />,
      dataIndex: 'businessKey',
      sorter: (a, b) => stringComparison(a.businessKey, b.businessKey),
      sortDirections: ['ascend', 'descend'],
      width: 140,
    },
    {
      title: <FormattedMessage id="NotDelivered.PackageRegistered" />,
      dataIndex: 'createDate',
      render: createDate => dayjs(createDate).format(dateFormat),
      sorter: (a, b) => dateComparison(a.createDate, b.createDate),
      sortDirections: ['ascend', 'descend']
    },
    {
      title: <FormattedMessage id="ActionDeliveries.TH.Location" />,
      dataIndex: 'location',
      sorter: (a, b) => stringComparison(a.location, b.location),
    },
    {
      title: <FormattedMessage id="CreateDelivery.Address" />,
      dataIndex: ['recipient', 'street'],
      sorter: (a, b) => stringComparison(a.street, b.street),
    },
    {
      title: <FormattedMessage id="NotDelivered.LastMessageSent" />,
      dataIndex: 'lastMessageSent',
      render: lastMessageSent => lastMessageSent == null
        ? f('ActionDeliveries.NoMessage')
        : dayjs(lastMessageSent.timeSentUTC).format(dateFormat),
      sorter: (a, b) => dateComparison(a.lastMessageSent, b.lastMessageSent),
      sortDirections: ['ascend', 'descend']
    },
    {
      title: <FormattedMessage id="ActionDeliveries.TH.Status" />,
      dataIndex: 'currentState',
      render: currentState => translations[currentState.stateId]
        ? translations[currentState.stateId]
        : currentState.name,
      sorter: (a, b) => {
        const translatedA = translations[a.currentState.stateId]
          ? translations[a.currentState.stateId]
          : a.currentState.name;
        const translatedB = translations[b.currentState.stateId]
          ? translations[b.currentState.stateId]
          : b.currentState.name;
        return translatedA.localeCompare(translatedB);
      },
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: <FormattedMessage id="CreateDelivery.Sender" />,
      dataIndex: 'sender',
      render: sender => (sender ? sender.name : ''),
      sorter: (a, b) => stringComparison(a.sender, b.sender, sender => sender.name),
      sortDirections: ['ascend', 'descend']
    },
    {
      title: <FormattedMessage id="ActionDeliveries.TH.Description" />,
      dataIndex: 'description',
      sorter: (a, b) => stringComparison(a.description, b.description),
      sortDirections: ['ascend', 'descend']
    },
  ]

  const shouldInclude = col => {
    const key = dataIndexToKey(col.dataIndex)
    return baseColumns.includes(key) || allowedColumns.get(key)?.used
  }

  const shouldShow = col => {
    const key = dataIndexToKey(col.dataIndex)
    return baseColumns.includes(key) || allowedColumns.get(key)?.required
  }

  const filtered = columns
    .filter(col => col.key === 'orderType'
      ? shouldInclude(col) && checkPermission(permissions, PERMISSION.readCollection)
      : shouldInclude(col)
    )

  const mapped = filtered.map(col => ({
    ...col,
    key: dataIndexToKey(col.dataIndex),
    visible: shouldShow(col),
  }))

  return mapped
}

export const searchableProps = [
  ['recipient', 'name'],
  'senderOrderId',
  'location',
  ['currentState', 'name'],
  'orderType'
]

