import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {
  Form, Row, Col, Layout, Input, Button, Select, message, Spin, Typography,
} from 'antd';
import { map, find, get } from 'lodash';

import { API } from '../../AxiosWrapper';
import Back from '../shared/Back';
import { useHandleApiError, useFormatMessage } from '../shared/hooks'

const { Item } = Form;
const { Option } = Select;
const { Title } = Typography

const maxLength = 100

const justifyRight = {
  display: 'flex',
  justifyContent: 'end'
}

const CreateBranch = ({ branches, setBranches }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const f = useFormatMessage()
  const handleApiError = useHandleApiError();

  const [numRows, setNumRows] = useState(5);
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [posting, setPosting] = useState(false);
  const [data, setData] = useState(null);

  const getBranch = id => {
    setLoading(true);
    API.GET(`Site/${id}`)
      .then(response => setData(response.data))
      .catch(handleApiError)
      .finally(() => setLoading(false))
  };

  useEffect(() => {
    const id = new URL(window.location).searchParams.get('id');
    if (id) getBranch(id);
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getCompanies = () => {
    API.GET('Company')
      .then((response) => setCompanies(response.data))
      .catch(handleApiError)
  };

  useEffect(() => getCompanies(), []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => form.resetFields(), [form, data]); // https://github.com/ant-design/ant-design/issues/22372

  const handleCreate = (model) => {
    API.POST('Site', model)
      .then(({ data }) => {
        message.success(f('CreateBranch.SuccessfullyCreated'))
        setBranches([...branches, data])
        history.push('/admin/branches')
      })
      .catch(handleApiError)
      .finally(() => setPosting(false))
  }

  const handleUpdate = (model) => {
    const id = new URL(window.location).searchParams.get('id');
    API.POST(`Site/${id}`, model)
      .then(({ data }) => {
        message.success(f('CreateBranch.SuccessfullyUpdated'))
        setBranches(branches.map(b => b.id === data.id ? data : b))
        history.push('/admin/branches')
      })
      .catch(handleApiError)
      .finally(() => setPosting(false))
  }

  const onFinish = (values) => {
    const config = Object.keys(values).reduce((acc, key) => {
      if (values[key] && key.startsWith('value-')) {
        const relatedKey = key.replace('value', 'key')
        const customKey = values[relatedKey]
        acc[customKey] = values[key]
      }
      return acc
    }, {})

    const model = {
      name: values.name.trim(),
      companyId: values.companyId,
      identifier: values.identifier,
      staffEmailAddress: values.staffEmailAddress?.trim(),
      address: {
        street: values.street.trim(),
        city: values.city.trim(),
        postalCode: values.postalCode
      },
      config: config
    }

    setPosting(true)
    if (data) 
      handleUpdate(model);
    else 
      handleCreate(model);
  };

  const addNew = () => {
    setNumRows(numRows + 1);
  }

  const keyValueRows = map(Array(numRows), (_, i) => (
    <Row gutter={40} key={i}>
      <Col span={12}>
        <Item
          label={<FormattedMessage id="Input.Key" />}
          name={`key-${i + 1}`}
        >
          <Input />
        </Item>
      </Col>
      <Col span={12} >
        <Item
          label={<FormattedMessage id="Input.Value" />}
          name={`value-${i + 1}`}
        >
          <Input />
        </Item>
      </Col>
    </Row>
  ));

  if (loading) return <Spin size="large" />;

  const defaultCompanyId = get(find(companies, com => com.isDefault), 'id')

  return (
    <Layout.Content>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          name: data?.name,
          identifier: data?.identifier,
          street: data?.address?.street,
          city: data?.address?.city,
          postalCode: data?.address?.postalCode,
          companyId: data?.companyId || defaultCompanyId,
        }}
      >
        <Row gutter={32}>
          <Col xs={24} lg={{ span: 12 }}>
            <Title level={2} style={{ marginBottom: 16 }}>
              <FormattedMessage id={data ? 'CreateBranch.EditBranch' : 'Sidebar.CreateBranch'} />
            </Title>
            <Back />

            <div className="form-style">
              <Item
                label={<FormattedMessage id="SenderUser.Name" />}
                name="name"
                rules={[
                  { required: true, whitespace: true, message: f('Validation.NameRequired') }
                ]}
                hasFeedback
              >
                <Input maxLength={maxLength} />
              </Item>
              <Item
                label={<FormattedMessage id="Senders.IdentificationNumber" />}
                name="identifier"
                rules={[
                  { required: true, whitespace: true, message: f('Validation.NationalIDRequired') },
                  { pattern: /^\d+$/, message: f("Validation.OnlyNumbers") },
                  { len: 10, message: f("Validation.NationalIDLength") }
                ]}
                hasFeedback
              >
                <Input maxLength={10} />
              </Item>
              <Item
                name="street"
                label={<FormattedMessage id="CreateDelivery.Address" />}
                rules={[
                  { required: true, whitespace: true, message: f('Validation.AddressRequired') }
                ]}
                hasFeedback
              >
                <Input maxLength={maxLength} />
              </Item>
              <Item
                name="city"
                label={<FormattedMessage id="InfoSender.City" />}
                rules={[
                  { required: true, whitespace: true, message: f('Validation.CityRequired') }
                ]}
                hasFeedback
              >
                <Input maxLength={maxLength} />
              </Item>
              <Item
                name="postalCode"
                label={<FormattedMessage id="InfoSender.PostalCode" />}
                rules={[
                  { required: true, whitespace: true, message: f('Validation.PostalCodeRequired') },
                  { pattern: /^\d+$/, message: f("Validation.OnlyNumbers") },
                  { len: 3, message: f("Validation.PostalCodeLength") }
                ]}
                hasFeedback
              >
                <Input maxLength={3} />
              </Item>
              <Item
                name="companyId"
                label={<FormattedMessage id="ActionDeliveries.TH.Company" />}
                rules={[{ required: true, message: f('Validation.CompanyRequired') }]}
                hasFeedback
              >
                <Select
                  showSearch
                  filterOption
                  optionFilterProp="children"
                  loading={loading}
                >
                  {map(companies, (company) => (
                    <Option key={company.id} value={company.id}>
                      {company?.information?.name}
                    </Option>
                  ))}
                </Select>
              </Item>
              <Item
                name="staffEmailAddress"
                label={<FormattedMessage id="CreateBranch.StaffEmailAddress" />}
                rules={[{ type: 'email', message: f('Validation.EmailInvalid') }]}
                hasFeedback
              >
                <Input type="email" maxLength={maxLength} />
              </Item>
              <Item>
                <Button type="primary" shape="round" htmlType="submit" loading={posting}>
                  <FormattedMessage id={data ? 'CreateSender.Save' : 'Sidebar.CreateBranch'} />
                </Button>
              </Item>
            </div>
          </Col>

          <Col xs={24} lg={{ span: 9 }}>
            <Row>
              <Col span={24}>
                <Title level={2} style={{ marginBottom: 8 }}><FormattedMessage id="CreateBranch.SetupValue" /></Title>
              </Col>
              <Col span={24} style={justifyRight}>
                <Button type="link" onClick={addNew} className="link-style">
                  + <FormattedMessage id="Button.AddValue" />
                </Button>
              </Col>
            </Row>
            {keyValueRows}
          </Col>
        </Row>
      </Form>
    </Layout.Content>
  );
}

export default CreateBranch;
