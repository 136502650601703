import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Form, Input } from 'antd';

import { useFormatMessage } from '../shared/hooks'

const { Item } = Form;

const Shelves = ({ returningOld }) => {
  const f = useFormatMessage()

  return (
    <>
      <Item
        name="location"
        rules={[{ type: 'string', required: true, message: f('CreateDelivery.ShelfRequired') }]}
        label={<FormattedMessage id="CreateDelivery.ShelfPlaceholder" />}
      >
        <Input />
      </Item>

      {returningOld &&
        <>
          <Item
            name="oldItemDescription"
            rules={[{ required: false, whitespace: true }]}
            label={<FormattedMessage id="CreateDelivery.OldItemDescription" />}
            style={{ marginBottom: 40 }}
          >
            <Input />
          </Item>
          <Item
            name="oldLocation"
            rules={[{ type: 'string', required: true, message: f('CreateDelivery.ShelfRequired') }]}
            label={<FormattedMessage id="CreateDelivery.ShelfPlaceholder" />}
          >
            <Input />
          </Item>
        </>
      }
    </>
  )
}

export default Shelves
