import ReactToPrint from 'react-to-print';
import { FormattedMessage } from 'react-intl';
import { Button } from 'antd';
import { forwardRef, useRef } from 'react';


const PrintWrap = forwardRef((props, ref) => {
    return (
      <div ref={ref} className="sheet">
        <div className={props.bodyClass}>
          {props.children}
        </div>
      </div>
    )
})


const PrintButton = ({children}) => {
  const componentRef = useRef()
  const bodyClass = "zlabel"
  return (
    <>
      <ReactToPrint
        trigger={() => <Button><FormattedMessage id="AboutDelivery.PrintLabel" /></Button>}
        content={() => componentRef.current}
        bodyClass={bodyClass}
      />
      <div style={{ display: "none" }}>
        <PrintWrap
          bodyClass={bodyClass}
          ref={componentRef}
        >
          {children}
        </PrintWrap>
      </div>
    </>
  )
}
export default PrintButton;
