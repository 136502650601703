import React, { useContext } from "react";
import { Form, Input } from 'antd';

import { useFormatMessage } from '../hooks'
import { emailOrPhoneRequired } from '../utils'
import { FieldContext } from "../FieldContext"

const { Item } = Form

const Email = ({ readOnly = false } = {}) => {
  const f = useFormatMessage()
  const form = Form.useFormInstance()
  const fields = useContext(FieldContext)

  const isUsed = name => fields.get(name)?.used
  const isRequired = name => fields.get(name)?.required

  if (!isUsed("email") && !isUsed("emailOrPhone")) return null

  const dependencies = []
  const rules = [
    {
      required: isRequired("email"),
      message: f('CreateDelivery.EmailRequired'),
    },
    {
      type: 'email',
      message: f('CreateDelivery.EmailError'),
    }
  ]

  if (isRequired("emailOrPhone")) {
    rules.push({
      validator: () => emailOrPhoneRequired(form, f),
      message: f('CreateDelivery.EmailOrPhoneRequired')
    })
    dependencies.push("phone")
  }

  return (
    <Item
      name="email"
      label={f('CreateDelivery.Email')}
      rules={rules}
      dependencies={dependencies}
      hasFeedback
    >
      <Input type="email" maxLength={100} readOnly={readOnly} />
    </Item>
  )
}

export default Email
