import React from 'react';
import { Row, Col, Table, Typography } from 'antd';
import { FormattedMessage } from 'react-intl'

import { useFormatMessage } from '../shared/hooks'

const { Title } = Typography

//hack copy paste from locker info
export const sizeTranslations = {
  "3x3": 'Box.Size.Keys',
  "11x4": 'Box.Size.Small',
  "12x4": 'Box.Size.Small',
  "11x10": 'Box.Size.Mid',
  "12x10": 'Box.Size.Mid',
  "11x16": 'Box.Size.Big',
  "12x16": 'Box.Size.Big',
  "11x34": 'Box.Size.Big',
  "12x34": 'Box.Size.Big',
  "11x69": 'Box.Size.Super',
  "12x69": 'Box.Size.Super',
}

const columns = [
  {
    title: () => <FormattedMessage id="CreateDelivery.CompartmentSizeDescription" />,
    key: 'sizeDescription',
    dataIndex: 'sizeDescription',
  },
  {
    title: () => <FormattedMessage id="CreateDelivery.CompartmentSize" />,
    key: 'sizeText',
    dataIndex: 'sizeText',
  },
  {
    title: () => <FormattedMessage id="CreateDelivery.FreeCell" />,
    key: 'numAvailable',
    dataIndex: 'numAvailable',
  },
  {
    title: () => <FormattedMessage id="CreateDelivery.Total" />,
    key: 'numTotal',
    dataIndex: 'numTotal',
  },
];

const BoxInfo = ({ lockers }) => {
  const f = useFormatMessage()

  if (!lockers) return

  return (
    <Col span={22}>
      <Row>
        <Title level={2}>{lockers[0]?.siteName}</Title>
      </Row>
      <Row>
        <Table
          columns={columns}
          dataSource={lockers.map(locker => ({...locker, sizeDescription:f(sizeTranslations[locker.size])}))}
          pagination={false}
          showHeader={true}
          rowKey={(rec) => rec.siteName + rec.size}
          scroll={{ x: 'max-content' }}
        />
      </Row>
    </Col>
  );
}

export default BoxInfo;
