import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl'
import { Button, Row, Checkbox, Divider, Tooltip } from 'antd'
import { DownOutlined, DragOutlined } from '@ant-design/icons'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { isEqual } from 'lodash'

const btnStyle = {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  border: 'none',
}

const menuStyle = {
  background: 'white',
  padding: '8px',
  position: 'absolute',
  top: 48,
  zIndex: 9,
}

const areColumnsSame = (columns1, columns2) => {
  const keys1 = columns1.map(c => c.key).sort()
  const keys2 = columns2.map(c => c.key).sort()
  return isEqual(keys1, keys2)
}

/**
 * Changes visible property and order of table columns
 */
const VisibleInfo = ({ tableName, columns, setColumns, loading }) => {

  const [open, setOpen] = useState(false)
  const [initialColumns, setInitialColumns] = useState()
  const [initialIndex, setInitialIndex] = useState()
  const [newIndex, setNewIndex] = useState()

  const mapToStored = storedColumns => storedColumns.map(storedCol => ({
    ...columns.find(col => col.key === storedCol.key),
    visible: storedCol.visible,
  }))

  const mapToNew = storedColumns => columns.map(col => {
    const storedCol = storedColumns.find(stored => stored.key === col.key)
    return {
      ...col,
      visible: storedCol ? storedCol.visible : col.visible,
    }
  })

  const mapColumns = storedColumns => areColumnsSame(columns, storedColumns)
    ? mapToStored(storedColumns)
    : mapToNew(storedColumns) // not preserving saved order

  const orderedColumns = localStorage.getItem(tableName)
    ? mapColumns(JSON.parse(localStorage.getItem(tableName)))
    : columns

  const closeOnClickOutside = e => {
    if (e.target.tagName === 'DIV') setOpen(false)
  }

  // only once
  useEffect(() => {
    if (localStorage.getItem(tableName))
      setColumns(orderedColumns) // for parent table
    setInitialColumns(columns) // for reset

    document.addEventListener('click', closeOnClickOutside)

    return () => window.removeEventListener('click', closeOnClickOutside)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const saveColumns = newColumns => {
    setColumns(newColumns)
    const mapped = newColumns.map(({ key, visible }) => ({ key, visible }))
    localStorage.setItem(tableName, JSON.stringify(mapped))
  }

  const changeVisible = ({ target }) => {
    const newColumns = orderedColumns.map(col => target.name === col.key
      ? { ...col, visible: target.checked }
      : col
    )
    saveColumns(newColumns)
  }

  const changeVisibleAll = ({ target }) => {
    const newColumns = orderedColumns.map(col => ({ ...col, visible: target.checked }))
    saveColumns(newColumns)
  }

  const reset = () => {
    localStorage.removeItem(tableName)
    setColumns(initialColumns)
  }

  const onDragOver = (e, i) => {
    e.preventDefault()
    if (i !== initialIndex) setNewIndex(i)
  }

  const onDragEnd = (startIndex = initialIndex, endIndex = newIndex) => {
    const newColumns = [...orderedColumns]
    const [draggedItem] = newColumns.splice(startIndex, 1)
    newColumns.splice(endIndex, 0, draggedItem)
    saveColumns(newColumns)
  }

  const onDragEndMobile = ({ source, destination }) => {
    if (!destination) return // if outside list
    onDragEnd(source.index, destination.index)
  }

  const menuHeader = (
    <>
      <FormattedMessage id="Config.ChooseColumns" />
      <Divider style={{ margin: '4px 0px 4px 0px' }} />
      <Checkbox
        onChange={changeVisibleAll}
        checked={orderedColumns.every(col => col.visible)}
      >
        <FormattedMessage id="Config.SelectAll" />
      </Checkbox>
      <Divider style={{ margin: '4px 0px 4px 0px' }} />
    </>
  )

  const renderRow = (key, title, visible) => (
    <Row type="flex" justify="space-between">
      <Checkbox name={key} checked={visible} onChange={changeVisible}>
        {title}
      </Checkbox>
      <DragOutlined />
    </Row>
  )

  const resetButton = (
    <Tooltip placement="bottom" title={<FormattedMessage id="Config.ResetToDefaults" />}>
      <Button onClick={reset} style={{ marginTop: 4 }}>
        <FormattedMessage id="Config.Reset" />
      </Button>
    </Tooltip>
  )

  // not working on mobile
  const desktopMenu = orderedColumns.map(({ key, title, visible }, i) => (
    <div
      key={key}
      draggable
      onDragStart={() => setInitialIndex(i)}
      onDragOver={e => onDragOver(e, i)}
      onDragEnd={onDragEnd}
      style={{ cursor: 'grab' }}
    >
      {renderRow(key, title, visible)}
    </div>
  ))

  // not working on desktop
  const mobileMenu = (
    <DragDropContext onDragEnd={onDragEndMobile}>
      <Droppable droppableId="droppable">
        {provided => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {orderedColumns.map(({ key, title, visible }, i) => (
              <Draggable key={key} draggableId={key} index={i}>
                {provided => (
                  <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    {renderRow(key, title, visible)}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )

  const menu = (
    <div style={menuStyle}>
      {menuHeader}
      {'ontouchstart' in window ? mobileMenu : desktopMenu}
      {resetButton}
    </div>
  )

  return (
    <>
      <Button
        style={btnStyle}
        loading={loading}
        onClick={() => setOpen(!open)}
      >
        <FormattedMessage id="Config.Button" />
        <DownOutlined />
      </Button>
      {open && menu}
    </>
  )
}

export default VisibleInfo
