import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';
import { Form, Row, Layout, Col, Checkbox, Button, Input, Typography } from 'antd';

import { filterData, filterObjectValues } from '../shared/utils';
import { API } from '../../AxiosWrapper';
import Sender from '../shared/Sender';
import { useColumns, searchableProps } from '../shared/useColumns';
import VisibleInfo from '../shared/VisibleInfo';
import DateRange from '../shared/DateRange';
import DeliveryTable from '../shared/DeliveryTable'
import { SearchIcon } from '../shared/icons'
import { useHandleApiError, useFormatMessage } from '../shared/hooks'
import ExportToExcel from '../shared/ExportToExcel'

const { Item } = Form;
const { Title } = Typography

const initialPageSize = 10;

const AllShipments = ({ branchId, defaultSenderId, permissions, senders, allowedColumns }) => {
  const f = useFormatMessage()
  const handleApiError = useHandleApiError()
  const columns = useColumns(permissions, allowedColumns)

  const [deliveries, setDeliveries] = useState([]);
  const [senderId, setSenderId] = useState(defaultSenderId);
  const [startDate, setStartDate] = useState(dayjs().subtract(30, 'day'));
  const [endDate, setEndDate] = useState(dayjs());
  const [showOnlyDelivered, setShowOnlyDelivered] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(initialPageSize);
  const [searchString, setSearchString] = useState('');

  const getDeliveryData = () => {
    if (!startDate || !endDate) return;

    let url = `Delivery?DateFrom=${startDate.toISOString()}&DateTo=${endDate.toISOString()}`
    if (branchId) url += `&branchId=${branchId}`;
    if (senderId) url += `&senderId=${senderId}`;

    setLoading(true);
    API.GET(url)
      .then(response => setDeliveries(response.data
        .map(d => ({ ...d, senderOrderId: d.senderOrderID })).reverse())) //TODO fix camelCase on backend
      .catch(handleApiError)
      .finally(() => setLoading(false))
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(getDeliveryData, [branchId, endDate, senderId, startDate])

  const onSenderChange = (value) => {
    setSenderId(value);
    // getDeliveryData(value, branchId, startDate, endDate);
  };

  const onDateChange = (values) => {
    if (!values) return;
    const { startValue, endValue } = values;
    setStartDate(startValue);
    setEndDate(endValue);
    // getDeliveryData(senderId, branchId, startValue, endValue);
  };

  const onCheckboxChange = ({ target }) => {
    setShowOnlyDelivered(target.checked);
  };

  const handleSearch = (e) => {
    setSearchString(e.target.value)
  }

  const filteredDeliveries = filterData(({ senderId, showOnlyDelivered, startDate, endDate }), deliveries)
    .filter((obj) => filterObjectValues(obj, searchableProps, searchString));

  const [orderedColumns, setOrderedColumns] = useState(columns)

  const visibleColumns = orderedColumns.filter(col => col.visible)

  const seeLessButton = <Button type="link" onClick={() => setPageSize(initialPageSize)}>{f('Table.SeeLess')}</Button>;

  const seeAllButton = <Button type="link" onClick={() => setPageSize(filteredDeliveries.length)}>{f('Table.SeeAll')}</Button>;

  return (
    <Layout.Content>
      <Title level={2}><FormattedMessage id="Sidebar.AllShipments" /></Title>

      <Form layout="vertical">
        <Row gutter={24}>
          <Col xs={24} lg={8}>
            <Item label={<span><FormattedMessage id="Input.Search" /></span>}>
              <Input
                className="standard-input"
                suffix={<SearchIcon />}
                onChange={handleSearch}
                placeholder={f('Input.SearchList')}
              />
            </Item>
          </Col>
          <Col xs={24} lg={8}>
            <DateRange
              onDateChange={onDateChange}
              startDate={startDate}
              endDate={endDate}
            />
          </Col>
          <Col xs={24} lg={8} className="justify-end-responsive">
            <Sender senders={senders} senderId={senderId} onSenderChange={onSenderChange} />
          </Col>
        </Row>
        <Row>
          <Col span={6} >
            <Item style={{ marginBottom: 0 }}>
              <Checkbox defaultChecked={showOnlyDelivered} onChange={onCheckboxChange}>
                <FormattedMessage id="AllShipments.ShowOnlyDelivered" />
              </Checkbox>
            </Item>
          </Col>
        </Row>
      </Form>

      <Row>
        <Col span={8}>
          <ExportToExcel dataSource={filteredDeliveries} columns={visibleColumns} />
        </Col>
        <Col span={8} style={{ textAlign: 'center' }}>
          {pageSize === filteredDeliveries.length && seeLessButton}
        </Col>
        <Col span={8} style={{ display: 'flex', justifyContent: 'end' }}>
          <VisibleInfo
            tableName="AllShipments"
            columns={orderedColumns}
            setColumns={setOrderedColumns}
            loading={loading}
          />
        </Col>
      </Row>
      <DeliveryTable
        columns={visibleColumns}
        dataSource={filteredDeliveries}
        loading={loading}
        pageSize={pageSize}
      />
      {(filteredDeliveries.length > 0) &&
        <Row className="see-all-row" justify="center">
          {pageSize === filteredDeliveries.length ? seeLessButton : seeAllButton}
        </Row>
      }
    </Layout.Content>
  );
}

export default AllShipments;
