import React from 'react';
import { Redirect } from 'react-router-dom';

import Profile from './components/Profile';

const AuthRequired = ({ user, component }) => {
  if (!user) return <Redirect to='/' />;

  if (component.type.name === Profile.name || (user.permissions && user.permissions.length)) {
    return component;
  } else {
    return <Redirect to='/settings/profile' />;
  }
}

export default AuthRequired;