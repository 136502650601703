import React from 'react';
import { Form, Select } from 'antd';
import { useFormatMessage } from '../hooks';

const { Item } = Form;

const Sender = ({ senders = [], orderType, editing = false }) => {
  const f = useFormatMessage()
  const options = senders.map(s => ({ value: s.id, label: s.name }))

  return (
    <Item
      name="senderId"
      hidden={senders.length === 1}
      label={orderType === 'collection'
        ? f('CreateCollection.ReceivingCompany')
        : f('CreateDelivery.Sender')}
      hasFeedback
      rules={[{
        required: true, message: f("CreateDelivery.SenderRequired")
      }]}
    >
      {editing
        ?
        <Select
          optionFilterProp="label"
          optionLabelProp="label"
          showSearch
          options={options}
        />
        :
        <Select
          optionLabelProp="label"
          open={false}
          suffixIcon={null}
          options={options}
        />
      }
    </Item>
  )
}
export default Sender
