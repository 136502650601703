import { SHOW_SHELF_BUTTON } from '../../Constants';

export const barcodeOptions = {
  height: 80,
  format: 'CODE128',
};

export const isInBox = (stateId) =>
  stateId === 'customer-picks-up-from-box' ||
  stateId === 'customer-identification' ||
  stateId === 'send-pickup-code' ||
  stateId === 'customer-pickup' ||
  stateId === 'send-staff-reminder' ||
  stateId === 'send-box-reminder';

export const canCancelBoxBefore = (stateId) => stateId === 'order-placed-in-box';

export const canMoveToShelf = (stateId) =>
  SHOW_SHELF_BUTTON &&
  (stateId === 'move-package-from-box' ||
    stateId === 'pick-shelf-or-box' ||
    stateId === 'package-moved-from-box');

export const canDecideDelivery = (stateId) =>
  stateId === 'decide-delivery-method' ||
  stateId === 'decide-delivery-method-pre-lockdown';

export const canDecideDeliveryPre = (stateId) =>
  stateId === 'decide-delivery-method-pre-lockdown';

export const canReclaim = (stateId) =>
  stateId === 'move-package-from-box' ||
  stateId === 'request-lockdown' ||
  stateId === 'package-moved-from-box-v2' ||
  stateId === 'package-moved-from-box' ||
  stateId === 'lockdown-notification-timeout' ||
  stateId === 'BoundaryEvent_0h6szie' ||
  stateId === 'send-lockdown-notification' ||
  stateId === 'end-lockdown-notification' ||
  stateId === 'EndEvent_038psog';

export const canPutInBox = (stateId) => stateId === 'order-placed-in-box';

export const canPickupFromBox = (stateId) =>
  stateId === 'collection-collected' ||
  stateId === 'staff-collection-reminder';

export const canDeliverPackage = (stateId) =>
  stateId === 'customer-picks-up-from-reception' ||
  stateId === 'send-shelf-reminder';

export const canDeliverPackageLate = (stateId) => stateId === 'return-to-sender';
export const canLockdownForReturn = (stateId) => stateId === 'return-to-sender';

export const canDeliverPackageToSender = (stateId) =>
  stateId === 'sender-picks-up-from-reception' ||
  stateId === 'sender-picks-up-from-receiption';

export const canCancelBoxAfter = (stateId) => stateId === 'decide-delivery';

export const canResendNotification = (stateId) => isInBox(stateId) || canDeliverPackage(stateId);

//collections
export const canCancelCollectionBeforeSendingToCustomer = (stateId) =>
  stateId === 'verify-reserve';
export const canCancelCollectionAfterSendingToCustomer = (stateId) =>
  stateId === 'collection-placed-in-box' ||
  stateId === 'collection-reminder-notification' ||
  stateId === 'collection-in-bad-locker-notification' ||
  stateId === 'related-shipment-delivered' ||
  stateId === 'related-delivery-check-timer' ||
  stateId === 'customer-check-delivery-notification' ||
  stateId === 'verify-locker' ||
  stateId === 'not-in-locker';
export const canVerifyCollection = (stateId) => stateId === 'verify-collection';
