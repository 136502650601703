/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Typography, Select, Spin, Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';

import { API } from '../../AxiosWrapper';
import DateRange from '../shared/DateRange';
import { labelStyle } from './style';
import { useHandleApiError, useFormatMessage } from '../shared/hooks'

const { Text } = Typography;
const { Option } = Select;

const format = "MM-DD-YYYY"

const deliveryTypes = [
  {
    id: 1,
    name: 'Home.AllDeliveries'
  },
  {
    id: 2,
    name: 'CreateDelivery.Box'
  },
  {
    id: 3,
    name: 'CreateDelivery.Shelf'
  }
]

const DateRangeStats = ({ branchId }) => {
  const f = useFormatMessage()
  const handleApiError = useHandleApiError();

  const [startDate, setStartDate] = useState(dayjs().subtract(1, 'week'));
  const [endDate, setEndDate] = useState(dayjs());
  const [deliveryType, setDeliveryType] = useState(1);

  const [loadingDateRangeStats, setLoadingDateRangeStats] = useState(false);
  const [dateRangeStats, setDateRangeStats] = useState({});

  const getDateRangeStats = (startDate, endDate, deliveryType) => {
    const dateFrom = startDate.format(format)
    const dateTo = endDate.format(format)
    
    setLoadingDateRangeStats(true)
    API.GET(`Dashboard/period?BranchId=${branchId}&DateFrom=${dateFrom}&DateTo=${dateTo}&deliveryType=${deliveryType}`)
      .then(response => setDateRangeStats(response.data))
      .catch(handleApiError)
      .finally(() => setLoadingDateRangeStats(false))
  }

  useEffect(() => getDateRangeStats(startDate, endDate, deliveryType), [branchId]) // eslint-disable-line react-hooks/exhaustive-deps

  const onDateChange = ({ startValue, endValue }) => {
    setStartDate(startValue);
    setEndDate(endValue);
    getDateRangeStats(startValue, endValue, deliveryType)
  }

  const getPopular = () => {
    const item = dateRangeStats.histogram.sort((a, b) => a.count < b.count)[0]
    return `${item.start.slice(0, -3)} - ${item.end.slice(0, -3)}`
  }

  const getAverage = () => {
    const hours = Math.floor(dateRangeStats.average / 3600);
    const minutes = Math.floor((dateRangeStats.average % 3600) / 60);
    const hourLocale = hours === 1 ? 'Time.hour' : 'Time.hours'
    const minuteLocale = minutes === 1 ? 'Time.minute' : 'Time.minutes'

    return `${hours} ${f(hourLocale)} ${minutes} ${f(minuteLocale)}`
  }

  const onDeliveryTypeChange = (type) => {
    setDeliveryType(type)
    getDateRangeStats(startDate, endDate, type)
  }

  return (
    <Form layout="vertical">
      <Card className="home-card" style={{ padding: 6, marginBottom: 40 }}>
        <div className="home-daterange" style={{ textAlign: 'center' }}>
          <DateRange
            onDateChange={onDateChange}
            startDate={startDate}
            endDate={endDate}
            allowClear={false}
          />
          {/* <Select
            style={{ textAlign: 'left', marginBottom: 16 }}
            value={deliveryType}
            onChange={onDeliveryTypeChange}
            className="homepage-select"
          >
            {deliveryTypes.map((sender) => (
              <Option key={sender.id} value={sender.id}>
                <FormattedMessage id={sender.name} />
              </Option>
            ))}
          </Select> */}
          <Row style={{ textAlign: 'center' }} >
            <Col span={12}>
              <Text style={labelStyle}><FormattedMessage id="Home.DeliveryTime" /></Text>
              <Text style={{ fontSize: 22 }}>
                {
                  loadingDateRangeStats
                    ? <Spin />
                    : !dateRangeStats?.totalDelivered
                      ? <FormattedMessage id="Home.NoData" />
                      : getAverage()
                }
              </Text>
            </Col>
            <Col span={12}>
              <Text style={labelStyle}><FormattedMessage id="Home.PickedUpTime" /></Text>
              <Text style={{ fontSize: 22 }}>
                {
                  loadingDateRangeStats
                    ? <Spin />
                    : !dateRangeStats?.totalDelivered
                      ? <FormattedMessage id="Home.NoData" />
                      : getPopular()
                }
              </Text>
            </Col>
          </Row>
        </div>
      </Card>
    </Form>
  );
}

export default DateRangeStats;
