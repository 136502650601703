import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Form, Table, Typography } from 'antd';

import BarcodeRow from './BarcodeRow';
import { columns } from './columns';
import { canPickupFromBox, canPutInBox, canReclaim } from './utils';
import { blacklistedStatuses } from '../../Constants';
import { addKey } from '../shared/utils';

const { Title } = Typography

const ShippingHistory = ({ data, loading, delivered }) => {

  const { history = [], deliveryCode, deliveryCodeUrl, pickupCode, pickupCodeUrl, reclaimCode, reclaimCodeUrl, relatedShipmentBusinessKey, currentState } = data

  const stateId = currentState?.stateId

  const dataSource = history
    .toReversed()
    .filter(row => !blacklistedStatuses.includes(row.topic))
    .map(addKey)

  return (
    <div>
      <Form>
        <Form.Item>
          <div>
            <Title level={3}><FormattedMessage id="AboutDelivery.ShippingHistory" /></Title>
          </div>
          <div>
            <Table
              columns={columns}
              dataSource={dataSource}
              pagination={false}
              showHeader={false}
              loading={loading}
              scroll={{ y: 350, x: 'max-content' }}
            />
          </div>
        </Form.Item>

        {canPutInBox(stateId) && deliveryCode && !delivered
          ? <BarcodeRow
            barCodeVal={deliveryCode}
            qrCodeVal={deliveryCodeUrl}
            out={false}
          />
          : null}
        {canPickupFromBox(stateId) && pickupCode && !delivered
          ? <BarcodeRow
            barCodeVal={pickupCode}
            qrCodeVal={pickupCodeUrl}
            out={true}
          />
          : null}
        {canReclaim(stateId) && reclaimCode && !delivered
          ? <BarcodeRow
            barCodeVal={reclaimCode}
            qrCodeVal={reclaimCodeUrl}
            out={true}
          />
          : null}
      </Form>

      {relatedShipmentBusinessKey
        ? (
          <div>
            <span>Tengd sending: </span>
            <Link to={`/about-delivery/${relatedShipmentBusinessKey}`}>{relatedShipmentBusinessKey}</Link>
          </div>
        ) : null
      }
    </div>
  )
}

export default ShippingHistory;
