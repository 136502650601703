import React, { useState } from 'react';
import { Form, Input, Button, Typography } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { FormattedMessage } from "react-intl";
import { authAPI } from "../AxiosWrapper";
import { useHandleApiError, useFormatMessage } from './shared/hooks'

const { Title } = Typography

const { Item } = Form

const Login = () => {
  const f = useFormatMessage()
  const handleApiError = useHandleApiError();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleSubmit = (values) => {
    setLoading(true);
    authAPI.POST('Users/token', values)
      .then(response => {
        console.log(response.data)
      })
      .catch(handleApiError)
      .finally(() => setLoading(false))
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
    >
      <Title level={2}>Login</Title>

      <div className="form-style" style={{ marginTop: 16 }}>
        <Item
          name="email"
          label={f('Profile.Email')}
          rules={[
            { type: 'email', message: f('Validation.EmailInvalid') },
            { required: true, whitespace: true, message: f('Validation.ContactEmailRequired') }
          ]}
        >
          <Input prefix={<UserOutlined />} />
        </Item>
        <Item
          name="password"
          label={f('Login.Password')}
          rules={[{ required: true, message: f('Validation.PasswordRequired') }]}
        >
          <Input.Password prefix={<LockOutlined />} />
        </Item>
        <Item>
          <Button type="primary" shape="round" htmlType="submit" loading={loading}>
            <FormattedMessage id="Login.SubmitButton" defaultMessage="Sign In" />
          </Button>
        </Item>
      </div>
    </Form>
  );
}

export default Login