import React from "react"
import { Form, Checkbox } from 'antd';

import { useFormatMessage } from '../hooks'
import { useContext } from "react";
import { FieldContext } from "../FieldContext";

const { Item } = Form

const RentalCar = ({ disabled = false } = {}) => {
  const f = useFormatMessage()
  const fields = useContext(FieldContext)

  if (!fields.get('rentalCar')?.used) return null

  return (
    <Item
      name="rentalCar"
      valuePropName="checkbox"
      messageVariables={{ msgLabel: f('delivery.rentalCar.validationName') }}
      rules={[
        {
          required: !!fields.get('rentalCar')?.required,
        }
      ]}
      hasFeedback
    >
      <Checkbox disabled={disabled}>{f('delivery.rentalCar.label')}</Checkbox>
    </Item>
  )
}

export default RentalCar
