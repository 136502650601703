import React from 'react';
import Barcode from 'react-barcode';
import QRCode from 'qrcode.react';
import { Row, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';

import { barcodeOptions } from './utils';

const { Title } = Typography

const BarcodeRow = ({ barCodeVal, qrCodeVal, out }) => {
  const title = out ? "CreateDelivery.CodeForBoxOut" : "CreateDelivery.CodeForBoxIn"
  return (
    <Row>
      <div>
        <div>
          <Title level={3}><FormattedMessage id={title} /></Title>
          <Barcode
            {...barcodeOptions}
            value={barCodeVal}
          />
          <QRCode
            value={qrCodeVal}
            size={128}
            bgColor={'#ffffff'}
            fgColor={'#000000'}
            level={'L'}
            includeMargin={true}
            renderAs={'svg'}
          />
        </div>
      </div>
    </Row>
  )
}

export default BarcodeRow;
