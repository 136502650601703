import React, { useState } from 'react';
import { Button, Col, Form, Input, Row, Space } from 'antd';
// import 'dayjs/locale/is';
import { FormattedMessage } from 'react-intl';

import { API } from '../../AxiosWrapper';
import { SHOW_SHELF_BUTTON } from '../../Constants';
import { canCancelBoxAfter, canCancelBoxBefore, canCancelCollectionAfterSendingToCustomer, canCancelCollectionBeforeSendingToCustomer, canDecideDelivery, canDecideDeliveryPre, canDeliverPackage, canDeliverPackageLate, canDeliverPackageToSender, canLockdownForReturn, canMoveToShelf, canResendNotification, canVerifyCollection, isInBox } from './utils';
import { useHandleApiError, useFormatMessage } from '../shared/hooks'

const { Item } = Form;

const BottomButtons = ({
  form, editing, stateId, setEditing, businessKey, handleSubmit, setLocation, setDelivered
}) => {
  const handleApiError = useHandleApiError();

  const [loadingLockdown, setLoadingLockdown] = useState(false);
  const [loadingShelf, setLoadingShelf] = useState(false);

  const canRemoveFromBox = isInBox(stateId);
  const f = useFormatMessage();

  const resendNotification = () => {
    let url = 'Delivery/' + businessKey + '/resend-last-notification';
    API.POST(url, {})
      .then((response) => { })
      .catch(handleApiError);
  };

  const requestLockdown = () => {
    setLoadingLockdown(true);
    let url = 'Delivery/' + businessKey + '/lockdown';
    API.POST(url, {})
      .then((response) => { })
      .catch(handleApiError)
      .finally(() => setLoadingLockdown(false))
  };

  const moveToShelfOld = (stuff) => {
    form.validateFields(['shelfLocation'])
      .then((values) => {
        const shelfLocation = values.shelfLocation
        const url = 'Delivery/' + businessKey + '/move-to-shelf?location=' + shelfLocation;
        const data = {
          businessKey: businessKey,
          location: shelfLocation,
        };
        setLoadingShelf(true);
        API.POST(url, data)
          .then((response) => setLocation(shelfLocation))
          .catch(handleApiError)
          .finally(() => setLoadingShelf(false))
      })
  };

  const handleDecide = (businessKey, method, delivered, shelfLocation) => {
    let path = 'decide-delivery-method';
    if (stateId === 'decide-delivery-method-pre-lockdown') {
      path = 'decide-delivery-method-pre-lockdown';
    }
    let fullPath = path + '?method=' + method;
    if (method === 'SHELF') {
      fullPath += '&location=' + shelfLocation;
    }
    else {
      form.setFieldValue('shelfLocation', '')
    }
    if (delivered) {
      setDelivered(true);
    }
    handleSubmit(businessKey, fullPath);
  };

  const handleDecideDeliverBox = () => {
    handleDecide(businessKey, 'BOX', false);
  };

  const handleDecideDeliverShelf = () => {
    form.validateFields(['shelfLocation'])
      .then((values) => {
        setLocation(values.shelfLocation)
        handleDecide(businessKey, 'SHELF', false, values.shelfLocation);
      })
  };

  const handleDecideDeliverDirect = () => {
    handleDecide(businessKey, 'COUNTER', true);
  };

  const handleDecideLater = () => {
    handleDecide(businessKey, 'UNDECIDED', false);
  };

  const handleBtn = (command) => {
    form.setFieldValue('shelfLocation', '')
    handleSubmit(businessKey, command);
  };

  const handleDeliver = (command) => {
    setDelivered(true);
    handleSubmit(businessKey, command);
  };

  if (editing) return (
    <Button shape="round" type="primary" htmlType="submit">
      <FormattedMessage id="AboutDelivery.Save" />
    </Button>
  )

  return (
    <div>
      <Item>
        <Button shape="round" type="primary" onClick={() => setEditing(true)}>
          <FormattedMessage id="AboutDelivery.ChangeInformation" />
        </Button>
      </Item>
      {canDeliverPackage(stateId)
        ? (
          <Item>
            <Button
              shape="round"
              type="primary"
              onClick={() => handleDeliver('deliver-to-customer')}
            >
              <FormattedMessage id="AboutDelivery.DeliverPackage" />
            </Button>
          </Item>
        )
        : null}
      {canDeliverPackage(stateId)
        ? (
          <Item>
            <Button
              shape="round"
              type="primary"
              onClick={() => handleBtn('cancel-shelf')}
            >
              <FormattedMessage id="AboutDelivery.MoveFromShelf" />
            </Button>
          </Item>
        )
        : null}
      {canDecideDeliveryPre(stateId)
        ? (
          <Item>
            <Button
              shape="round"
              type="primary"
              onClick={handleDecideLater}
            >
              <FormattedMessage id="CreateDelivery.DecideLater" />
            </Button>
          </Item>
        )
        : null}
      {canDecideDelivery(stateId)
        ? (
          <Item>
            <Button
              shape="round"
              type="primary"
              onClick={handleDecideDeliverDirect}
            >
              <FormattedMessage id="CreateDelivery.DeliverPackage" />
            </Button>
          </Item>
        )
        : null}
      {canDecideDelivery(stateId) && SHOW_SHELF_BUTTON
        ? (
          < Space.Compact >
            <Item
              name="shelfLocation"
              rules={[{
                required: true,
                message: <FormattedMessage id="CreateDelivery.ShelfRequired" />,
              }]}
            >
              <Input
                placeholder={f("CreateDelivery.ShelfPlaceholder")}
                style={{ width: '180px' }}
              />
            </Item>
            <Item>
              <Button
                shape="round"
                type="primary"
                onClick={handleDecideDeliverShelf}
                loading={loadingShelf}
              >
                <FormattedMessage id="CreateDelivery.MoveToShelf" />
              </Button>
            </Item>
          </Space.Compact>
        ) : null
      }
      {
        canDecideDelivery(stateId)
          ? (
            <Item>
              <Button
                shape="round"
                type="primary"
                onClick={handleDecideDeliverBox}
              >
                <FormattedMessage id="CreateDelivery.SendToBox" />
              </Button>
            </Item>
          ) : null
      }
      {
        canDeliverPackageLate(stateId)
          ? (
            <Item>
              <Button
                shape="round"
                type="primary"
                onClick={() => handleDeliver('deliver-to-customer-late')}
              >
                <FormattedMessage id="CreateDelivery.DeliverPackage" />
              </Button>
            </Item>
          )
          : null
      }
      {
        canDeliverPackageToSender(stateId)
          ? (
            <Item>
              <Button
                shape="round"
                type="primary"
                onClick={() => handleDeliver('deliver-to-sender')}
              >
                <FormattedMessage id="CreateDelivery.DeliverToSender" />
              </Button>
            </Item>
          )
          : null
      }
      {
        canLockdownForReturn(stateId)
          ? (
            <Item>
              <Button
                shape="round"
                type="primary"
                onClick={() => handleDeliver('lockdown-for-return')}
              >
                <FormattedMessage id="CreateDelivery.Resend" />
              </Button>
            </Item>
          )
          : null
      }
      {
        canCancelBoxBefore(stateId)
          ? (
            <Item>
              <Button
                shape="round"
                type="alert"
                onClick={() => handleBtn('cancel-box-before')}
              >
                <FormattedMessage id="CreateDelivery.CancelBox" />
              </Button>
            </Item>
          )
          : null
      }
      {
        canCancelBoxAfter(stateId)
          ? (
            <>
              <Item>
                <Button
                  shape="round"
                  type="primary"
                  onClick={() => handleBtn('cancel-box')}
                >
                  <FormattedMessage id="CreateDelivery.CancelBox" />
                </Button>
              </Item>

              <Item>
                <Button
                  shape="round"
                  type="secondary"
                  onClick={() => handleBtn('verify-item-in-box')}
                >
                  <FormattedMessage id="CreateDelivery.VerifyItemInBox" />
                </Button>
              </Item>
            </>
          )
          : null
      }
      {
        canMoveToShelf(stateId)
          ? (
            <Row gutter={24} style={{ width: '100%' }}>
              <Col span={{ xs: 24, sm: 24, md: 24, lg: 10 }} >
                <Item style={{ float: 'left' }}>
                  <Button
                    shape="round"
                    type="primary"
                    onClick={moveToShelfOld}
                    loading={loadingShelf}
                  >
                    <FormattedMessage id="CreateDelivery.MoveToShelf" />
                  </Button>
                </Item>
              </Col>
              <Col span={{ xs: 24, sm: 24, md: 24, lg: 10 }}>
                <Item
                  style={{ float: 'left' }}
                  name="shelfLocation"
                  rules={[{
                    required: true,
                    message: <FormattedMessage id="CreateDelivery.ShelfRequired" />
                  }]}
                >
                  <Input />
                </Item>
              </Col>
            </Row>)
          : canRemoveFromBox
            ? (
              <Item>
                <Button
                  shape="round"
                  type="primary"
                  onClick={requestLockdown}
                  loading={loadingLockdown}
                >
                  <FormattedMessage id="CreateDelivery.RemoveFromBox" />
                </Button>
              </Item>
            ) : null
      }
      {
        canResendNotification(stateId)
          ? (
            <Item>
              <Button
                shape="round"
                type="primary"
                onClick={resendNotification}
              >
                <FormattedMessage id="CreateDelivery.ResendNotification" />
              </Button>
            </Item>
          ) : null
      }
      {
        canCancelCollectionBeforeSendingToCustomer(stateId)
          ? (
            <>
              <Item>
                <Button
                  shape="round"
                  type="primary"
                  onClick={() => handleBtn('verify-collection-before')}
                >
                  <FormattedMessage id="CreateCollection.VerifyReservation" />
                </Button>
              </Item>
              <Item>
                <Button
                  shape="round"
                  type="primary"
                  onClick={() => handleBtn('cancel-collection-before')}
                >
                  <FormattedMessage id="CreateCollection.CancelReservation" />
                </Button>
              </Item>
            </>
          ) : null
      }
      {
        canCancelCollectionAfterSendingToCustomer(stateId)
          ? (
            <Item>
              <Button
                shape="round"
                type="primary"
                onClick={() => handleBtn('cancel-collection-waiting')}
              >
                <FormattedMessage id="CreateCollection.CancelBox" />
              </Button>
            </Item>
          ) : null
      }
      {
        canVerifyCollection(stateId)
          ? (
            <>
              <Item>
                <Button
                  shape="round"
                  type="primary"
                  onClick={() => handleBtn('verify-collection')}
                >
                  <FormattedMessage id="CreateCollection.VerifyCollectionReceived" />
                </Button>
              </Item>
              <Item>
                <Button
                  shape="round"
                  type="primary"
                  onClick={() => handleBtn('reject-collection')}
                >
                  <FormattedMessage id="CreateCollection.RejectCollection" />
                </Button>
              </Item>
            </>
          ) : null
      }
    </div >
  )
}

export default BottomButtons;
