import dayjs from 'dayjs';
import { translations } from '../../Constants';

const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

const dateFormat = 'YYYY-MM-DD HH:mm';

export const columns = [
  {
    key: 'topic',
    dataIndex: 'topic',
    render: (topic) => {
      return translations[topic] ? translations[topic] : topic;
    },
  },
  {
    key: 'timeSentUTC',
    dataIndex: 'timeSentUTC',
    render: (timeSentUTC) => {
      return dayjs(timeSentUTC).format(dateFormat);
    },
  },
  {
    key: 'timeSentUTC1',
    dataIndex: 'timeSentUTC',
    render: (timeSentUTC) => {
      return dayjs(timeSentUTC).fromNow();
    },
  },
]