import React from 'react';
import { Form, Select } from 'antd';
import { FormattedMessage } from 'react-intl';

const { Option } = Select;
const { Item } = Form;

const Sender = ({ senders, senderId, onSenderChange }) => {
  return (
    <Item label={<FormattedMessage id="CreateDelivery.Sender" />} >
      <Select
        className="standard-input"
        value={senderId}
        onChange={onSenderChange}
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {senders.length > 1 &&
        <Option key={0} value={0}>
          <FormattedMessage id="CreateDelivery.AllSenders" />
        </Option>
        }
        {senders.map((sender) => (
          <Option key={sender.id} value={sender.id}>
            {sender.name}
          </Option>
        ))}
      </Select>
    </Item>
  )
}

export default Sender;
