import React from "react"
import { Form, Input } from 'antd';

import { useFormatMessage } from '../hooks'
import { phoneValidator, emailOrPhoneRequired } from '../utils'
import { useContext } from "react";
import { FieldContext } from "../FieldContext";

const { Item } = Form

const Phone = ({ readOnly = false } = {}) => {
  const f = useFormatMessage()
  const form = Form.useFormInstance()
  const fields = useContext(FieldContext)

  const isUsed = name => fields.get(name)?.used
  const isRequired = name => fields.get(name)?.required

  if (!isUsed("phone") && !isUsed("emailOrPhone")) return null

  const dependencies = []
  const rules = [
    {
      required: isRequired("phone"),
      message: f('Validation.PhoneRequired')
    },
    {
      validator: phoneValidator,
      message: f('Validation.PhoneInvalid')
    }
  ]

  if (isRequired("emailOrPhone")) {
    rules.push({
      validator: () => emailOrPhoneRequired(form, f),
      message: f('CreateDelivery.EmailOrPhoneRequired')
    })
    dependencies.push("email")
  }

  return (
    <Item
      name="phone"
      label={f('CreateDelivery.Mobile')}
      rules={rules}
      dependencies={dependencies}
      hasFeedback
    >
      <Input type="tel" maxLength={15} readOnly={readOnly} />
    </Item>
  )
}

export default Phone
