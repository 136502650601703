import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import { ConfigProvider, theme } from "antd";

import locale_en from '../translations/en.json';
import locale_is from '../translations/is.json';
import Routes from './Routes'

const { defaultAlgorithm, darkAlgorithm } = theme;

const translations = {
  is: locale_is,
  en: locale_en,
}

const App = () => {
  const [locale, setLocale] = useState(localStorage.getItem('locale') || 'is')
  const [userTheme, setUserTheme] = useState(localStorage.getItem('userTheme') || 'light')

  const isDarkMode = userTheme === 'dark'

  // https://ant.design/docs/react/customize-theme
  const token = {
    borderRadius: 2,
    colorBgContainer: isDarkMode ? '#1F1F1F' : '#ffffff',
    colorBgLayout: isDarkMode ? '#000000' : '#F9F9F9',
    colorBorder: '#C2C2C2',
    colorFillSecondary: '#f0f0f0',
    colorLink: isDarkMode ? "#E1E8FF" : "#283A78",
    colorLinkHover: "#A9B6ED",
    colorPrimary: "#1B998B",
    colorText: isDarkMode ? '#ffffff' : '#000000',
    colorTextPlaceholder: isDarkMode ? '#FAFAFA' : '#1F1F1F',
    colorTextSecondary: isDarkMode ? '#ffffff' : '#000000',
    controlHeight: 48, // side effect: nav menu height
    controlOutline: '#C2C2C2',
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 13,
    fontSizeHeading2: 20,
    fontSizeHeading3: 16,
    fontSizeLg: 14,
    linkDecoration: "underline",
  }

  return (
    <ConfigProvider
      theme={{
        token,
        algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm,
        components: {
          Button: {
            boxShadow: 0,
            algorithm: true, // Enable algorithm
          },
        },
      }}>
      <IntlProvider locale={locale} messages={translations[locale]}>
        <Routes locale={locale} setLocale={setLocale} setUserTheme={setUserTheme} />
      </IntlProvider>
    </ConfigProvider>
  )
};

export default App;
