import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Layout, Form, Input, Button, message, Select, Collapse, Typography, theme } from 'antd';
import { FormattedMessage } from 'react-intl'

import { API } from "../AxiosWrapper";
import { AUTH_SERVER_LINK } from '../Constants';
import { useHandleApiError, useFormatMessage } from './shared/hooks'

const { Option } = Select;
const { Panel } = Collapse;
const { Item } = Form;
const { Title } = Typography

const Profile = ({ permissions, user, senders, branches, handleBranchChange, onUpdateUser }) => {
  const handleApiError = useHandleApiError();
  const f = useFormatMessage();
  const {
    token: { colorBgContainer }
  } = theme.useToken()

  const [posting, setPosting] = useState(false);
  const [permissionErrorShown, setPermissionErrorShown] = useState(false);

  const handleSubmit = (data) => {
    setPosting(true);
    API.POST('Users/defaults', data)
      .then(onUpdateUser)
      .catch(handleApiError)
      .finally(() => setPosting(false));
  }

  if (!permissionErrorShown && permissions.length <= 0) {
    message.error(f('Profile.No.Permissions'));
    setPermissionErrorShown(true);
  }

  return (
    <Layout.Content>
      <Title level={2}><FormattedMessage id="Header.Profile" /></Title>
      <Form
        layout="vertical"
        initialValues={{
          name: user.firstName,
          defaultBranchId: user.defaultBranchId,
          defaultSenderId: user.defaultSenderId,
        }}
        className="form-style"
        onFinish={handleSubmit}
      >
        <Item
          name="name"
          label={<FormattedMessage id="Profile.Name" />}
          rules={[{ required: true }]}
        >
          <Input />
        </Item>

        <Item
          name="defaultBranchId"
          label={<FormattedMessage id="Profile.DefaultBranch" />}
          rules={[{ required: true }]}
        >
          {user && branches.length > 0
            ? (
              <Select onChange={handleBranchChange}>
                {branches.length > 1 &&
                  <Option key={0} value={0}>
                    <FormattedMessage id="Header.AllBranches" />
                  </Option>
                }
                {branches.map((branch) => (
                  <Option key={branch.id} value={branch.id}>
                    {branch.name}
                  </Option>
                ))}
              </Select>
            )
            : <Select disabled placeholder={<FormattedMessage id="Branch.Unavailable" />} />}
        </Item>

        <Item
          name="defaultSenderId"
          label={<FormattedMessage id="Profile.DefaultSender" />}
          rules={[{ required: true }]}
        >
          {senders ?
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {senders && senders.map(sender => (
                <Option key={sender.id} value={sender.id}>
                  {sender.name}
                </Option>
              ))}
            </Select>
            :
            <Select disabled placeholder={<FormattedMessage id="Sender.Unavailable" />} />
          }
        </Item>

        <Item>
          <Button type="primary" shape="round" htmlType="submit" loading={posting}>
            <FormattedMessage id="Profile.Submit" />
          </Button>
        </Item>

        <Collapse style={{ background: colorBgContainer, border: 'none' }}>
          <Panel header={<FormattedMessage id="Profile.Permissions" />} style={{ border: 'none' }} >
            {permissions && permissions.map(p => <div key={p}>{p}</div>)}
          </Panel>
        </Collapse>
        {AUTH_SERVER_LINK ? (
          <p>
            <NavLink style={{ float: "right" }} to={{ pathname: AUTH_SERVER_LINK }} target="_blank">
              <FormattedMessage id="Profile.AuthSettings" />
            </NavLink>
          </p>
        ) : null}
      </Form>
    </Layout.Content>
  );
}

export default Profile;
