import dayjs from 'dayjs';
import { get, toLower, includes } from 'lodash';
import { PhoneNumberUtil } from 'google-libphonenumber';

import { PERMISSION } from '../../Constants'

const isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)

const regionCodePhone = "IS";

export const addKey = (obj, i) => ({ ...obj, key: i })

export function dateComparison(a, b, lookup = null) {
  if (!a && !b) return 0;
  if (!a) return -1;
  if (!b) return 1;
  if (lookup) {
    a = lookup(a);
    b = lookup(b);
    if (!a && !b) return 0;
    if (!a) return -1;
    if (!b) return 1;
  }
  let momA = dayjs(a);
  let momB = dayjs(b);
  if (momB.isBefore(momA)) return -1;
  return momA.isBefore(momB);
}

export function stringComparison(a, b, lookup = null) {
  if (!a && !b) return 0;
  if (!a) return -1;
  if (!b) return 1;
  if (lookup) {
    a = lookup(a);
    b = lookup(b);
    if (!a && !b) return 0;
    if (!a) return -1;
    if (!b) return 1;
  }
  return a.localeCompare(b);
}

export function boolComparison(a, b) {
  if (!a && !b) return 0;
  if (!a) return -1;
  if (!b) return 1;
}

export function filterData({ senderId, showOnlyDelivered, startDate, endDate }, lines) {
  let filteredData = lines;
  if (senderId) {
    filteredData = filteredData.filter(
      (line) => line.sender && line.sender.id === senderId
    );
  }
  if (showOnlyDelivered) {
    filteredData = filteredData.filter((line) => line.delivered);
  }
  if (startDate) {
    filteredData = filteredData.filter((line) =>
      dayjs(line.createDate).isSameOrAfter(startDate)
    );
  }
  if (endDate) {
    filteredData = filteredData.filter((line) =>
      dayjs(line.createDate).isSameOrBefore(endDate)
    );
  }
  return filteredData;
}

export function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

export const formSearchItemLayout = {
  labelCol: { xs: { span: 24 }, md: { span: 24 }, lg: { span: 6 } },
  wrapperCol: { xs: { span: 24 }, md: { span: 14 } },
};

export const checkPermission = (permissions, permission) => {
  if (permissions.includes(PERMISSION.accessAll)) return true;
  return permissions.includes(permission);
};

export const filterObjectValues = (obj, searchableProps, searchString = '') => searchableProps.some(
  key => includes(toLower(get(obj, key, '')), toLower(searchString))
)

export const getSanitizedPhone = (phone) => {
  if (!phone) return null;

  const phoneUtil = PhoneNumberUtil.getInstance();
  phone = phone.replace(/[^a-zA-Z0-9 ]/g, "");
  const numberChk = phoneUtil.parseAndKeepRawInput(phone, regionCodePhone);
  return numberChk.getNationalNumber();
};

const isValidPhone = (value) => {
  const phoneUtil = PhoneNumberUtil.getInstance();
  const number = phoneUtil.parseAndKeepRawInput(value, regionCodePhone);
  return phoneUtil.isValidNumberForRegion(number, regionCodePhone);
}

const isValidRange = value => value.match(/\d/g)?.length >= 3 && value.match(/\d/g)?.length <= 13;

/* using PhoneNumberUtil, invalidates non-Icelandic phone numbers */
export const phoneValidator = (rule, value) => {
  // allow empty phone number. Required should take care of that
  if (!value) return Promise.resolve()

  return isValidPhone(value)
    ? Promise.resolve()
    : Promise.reject()
}

/* not using PhoneNumberUtil, validates only digits length */
export const validatePhone = (_, value) => {
  if (!value || isValidRange(value)) {
    return Promise.resolve();
  }
  return Promise.reject();
}

//remove garbage and add hyphen
export const kennitalaNormalize = (value) => {
  value = value.replace(/[^0-9-]/g, "")
  const hasCorrectHyphen = value.indexOf("-") === 6
  value = value.replaceAll("-", "");
  if (hasCorrectHyphen || (value.length > 6)) {
    return value.slice(0, 6) + "-" + value.slice(6)
  } else {
    return value
  }
}

const kennitalaCheck = (value) => {
  if (!value) return ktError("empty");
  if (typeof (value) !== "string") return ktError("type must be string")
  //clean up the string. Replace all non-numbers with nothing
  const ktStr = value.replace(/\D/g, "")
  const kt = parseKennitala(ktStr)
  return kt
}

export const kennitalaValidatorOnChange = (_, value, formatter) => {
  const ktCheck = kennitalaCheck(value)
  //required takes care of empty
  if (ktCheck.error === "empty") return Promise.resolve()
  if (ktCheck.error === "too short") return Promise.resolve()
  if (ktCheck.error === "too long") return Promise.reject(formatter("Validation.NationalIDLength"))
  if (ktCheck.type === "Error") return Promise.reject(formatter("Validation.NationalIDFormat"))
  if (ktCheck.type !== "Person") return Promise.reject(formatter("Validation.NationalIDPersonOnly"))
  if (ktCheck.kennitala[9] !== "9" && ktCheck.kennitala[9] !== "0") return Promise.reject(formatter("Validation.NationalIDAge"))
  return Promise.resolve();
}

export const kennitalaValidatorFinal = (_, value, formatter) => {
  const ktCheck = kennitalaCheck(value)
  //required takes care of empty
  if (ktCheck.error === "empty") return Promise.resolve()
  if (ktCheck.error === "too short") return Promise.reject(formatter("Validation.NationalIDLength"))
  if (ktCheck.error === "too long") return Promise.resolve(formatter("Validation.NationalIDLength"))
  if (ktCheck.type === "Error") return Promise.reject(formatter("Validation.NationalIDFormat"))
  if (ktCheck.type !== "Person") return Promise.reject(formatter("Validation.NationalIDPersonOnly"))
  if (ktCheck.kennitala[9] !== "9" && ktCheck.kennitala[9] !== "0") return Promise.reject(formatter("Validation.NationalIDAge"))
  return Promise.resolve();
}

function ktError(error) {
  return {
    kennitala: null,
    valid: false,
    type: "Error",
    error: error,
  }
}

function isValidDate(year, month, day) {
  //we know that the values are 0-99
  if (month > 12) return false;
  if (month === 0) return false;
  if (day === 0) return false;
  if (day > 31) return false;
  const months30 = [4, 6, 9, 11] // months with 30 days
  //check february
  if (month === 2 && day === 29) {
    const leap = ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0)
    return leap
  }
  if (month === 2) return day <= 28
  //we've already checked 1-31
  //let's check if 31 is allowed or not
  let invalid = day === 31 && months30.includes(month)
  return !invalid
}

//allows only digits
export function parseKennitala(kennitala) {
  const mod11arr = [3, 2, 7, 6, 5, 4, 3, 2, 0, 0];

  if (typeof (kennitala) !== "string") return ktError("type must be string")
  const len = kennitala.length
  if (len < 10) return ktError("too short")
  if (len > 10) return ktError("too long")
  const numbers = new Array(10)
  let modSum = 0
  for (let i = 0; i < len; i++) {
    const ch = kennitala.charCodeAt(i) //no garbage collection
    // console.log(ch)
    if (ch >= 48 && ch < 58) {
      let num = ch - 48;
      modSum += mod11arr[i] * num;
      numbers[i] = num;
    } else return ktError("digits only")
  }
  //check digits
  const checkDigit = (11 - (modSum % 11)) % 11
  // console.log(checkDigit)
  if (checkDigit === 10) return ktError("bad checksum")
  if (numbers[8] !== checkDigit) return ktError("bad checksum")
  if (numbers[0] >= 8) return {
    kennitala: numbers.join(""),
    valid: true,
    type: "System",
  }
  let c = numbers[9] * 100 + 2000
  if (c > 2800) c -= 1000
  let d = numbers[0] * 10 + numbers[1]
  const m = numbers[2] * 10 + numbers[3]
  const y = c + numbers[4] * 10 + numbers[5]
  const isCompany = d > 31
  if (isCompany) d -= 40
  if (!isValidDate(y, m, d)) return ktError("bad date")
  return {
    kennitala: numbers.join(""),
    valid: true,
    type: isCompany ? "Company" : "Person",
    // date: {
    //   year: y,
    //   month: m,
    //   day: d,
    // }
  }
}

// const kennitalaRe = /^\s*\d{6}\s?-\s?\d{4}\s*$/
export function sanitizeKennitala(kennitala) {
  if (!kennitala) return null;
  return kennitala.replace(/\D/g, "")
}

export const emailOrPhoneRequired = (form, f) => {
  const phone = form.getFieldValue('phone')
  const email = form.getFieldValue('email')

  return phone || email
    ? Promise.resolve()
    : Promise.reject(new Error(f('CreateDelivery.EmailOrPhoneRequired')))
}
