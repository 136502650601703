import React from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Button } from 'antd';

const Back = () => {
  const history = useHistory();
  return (
    <Button
      type="link"
      onClick={() => history.goBack()}
      className="link-style"
    >
      <FormattedMessage id="Button.Back" />
    </Button>
  )
}

export default Back;