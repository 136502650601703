import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Form, Row, Col, Input, Select, message, Typography } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { map } from 'lodash';

import { notificationAPI } from '../../AxiosWrapper';
import { useHandleApiError, useFormatMessage, useTheme } from '../shared/hooks'
import Preview from './Preview'
import Parameters from './Parameters'
import SMSEditor from './SMSEditor'

const { Item } = Form;
const { Option } = Select;
const { Title } = Typography

const templateNames = ["order-in-box", "box-reminder", "order-on-shelf", "shelf-reminder", "moved-to-shelf", "locked-down", "sender-reminder", "staff-reminder", "locker-reserved", "customer-collection-reminder", "staff-collection-in-box", "staff-collection-reminder", "staff-locker-cancelled", "customer-locker-cancelled", "verify-locker", "unreserve-locker", "bad-locker", "invite-user", "add-user", "reinvite-user"]

const notificationTypes = {
  sms: 'SMS',
  email: 'Email',
}

const orderTypes = {
  delivery: 'Delivery'
}

const TemplateEditor = ({ senders, branches, locale }) => {
  const f = useFormatMessage()
  const [form] = Form.useForm()
  const theme = useTheme()

  const [templateLoading, setTemplateLoading] = useState(false)

  const isType = key => form.getFieldValue('notificationType') === key

  const getOwnerJson = (templateType) => {
    const ownerJson = {
      orderType: form.getFieldValue('orderType'),
      senderId: form.getFieldValue('senderId')?.toString(),
      branchId: form.getFieldValue('branchId')?.toString(),
      companyId: branches.find(b => b.id === form.getFieldValue('branchId'))?.companyId?.toString(),
    }
    const isMainTemplate = ["email-subject", "email", "sms"].includes(templateType)
    if (!isMainTemplate) {
      ownerJson.template = form.getFieldValue('templateName')
    }
    return JSON.stringify(ownerJson)
  }

  const getTemplate = ({ templateType, templateName }) => {
    setTemplateLoading(true);
    const ownerJson = getOwnerJson(templateType)
    /*
    notificationAPI.GET(`Notification/all/${ownerJson}/${templateType}/${templateName}/${locale}`)
      .then(({ data }) => {
        console.log(data)
      })
      .catch(error => message.error('Could not load template: ' + templateType))
    */
    notificationAPI.GET(`Notification/${ownerJson}/${templateType}/${templateName}/${locale}`)
      .then(({ data }) => {
        form.setFieldsValue({ [templateType]: data.text })
      })
      .catch(error => message.error('Could not load template: ' + templateType))
      .finally(() => setTemplateLoading(false))
  }

  const getData = () => {
    if (isType('email')) {
      getTemplate({ templateType: 'email-subject', templateName: form.getFieldValue('templateName') })
      getTemplate({ templateType: 'email-partial', templateName: 'main-text' })
      getTemplate({ templateType: 'parameter', templateName: 'title' })
    }
    if (isType('sms')) {
      getTemplate({ templateType: 'sms', templateName: form.getFieldValue('templateName') })
    }
  }

  useEffect(getData, [branches, locale]); // eslint-disable-line react-hooks/exhaustive-deps

  const missingFields = () => !form.getFieldValue('senderId') || !form.getFieldValue('branchId') || !form.getFieldValue('templateName')

  const putTemplate = ({ templateType, templateName, data }) => {
    setTemplateLoading(true);

    const ownerJson = getOwnerJson(templateType)
    const url = `Notification/${ownerJson}/${templateType}/${templateName}/${locale}`

    return notificationAPI.PUT(url, JSON.stringify(data));
  }

  const handleSelect = () => {
    getData()
    localStorage.setItem('templateEditor', JSON.stringify({
      branchId: form.getFieldValue('branchId'),
      senderId: form.getFieldValue('senderId'),
      templateName: form.getFieldValue('templateName'),
      notificationType: form.getFieldValue('notificationType'),
      orderType: form.getFieldValue('orderType'),
    }))
  }

  const onFinish = async () => {
    if (missingFields()) return

    const emailSubject = form.getFieldValue('email-subject')
    const parameter = form.getFieldValue('parameter')
    const emailPartial = form.getFieldValue('email-partial')
    const sms = form.getFieldValue('sms')

    const promises = []

    if (isType('email')) {
      if (emailSubject)
        promises.push(putTemplate({ templateType: 'email-subject', templateName: form.getFieldValue('templateName'), data: emailSubject }))
      if (parameter)
        promises.push(putTemplate({ templateType: 'parameter', templateName: 'title', data: parameter }))
      if (emailPartial)
        promises.push(putTemplate({ templateType: 'email-partial', templateName: 'main-text', data: emailPartial }))
    }

    if (isType('sms') && sms)
      promises.push(putTemplate({ templateType: 'sms', templateName: form.getFieldValue('templateName'), data: sms }))

    Promise.all(promises)
      .then(() => message.success('Successfully updated!'))
      .catch(useHandleApiError)
      .finally(() => setTemplateLoading(false))
  }

  return (
    <>
      <Title level={2} style={{ marginBottom: 16 }}><FormattedMessage id="Sidebar.TemplateEditor" /></Title>

      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          notificationType: 'sms',
          ...JSON.parse(localStorage.getItem('templateEditor'))
        }}
      >
        <Row gutter={24}>
          <Col span={12}>
            <Item name="notificationType" label={f('TemplateEditor.NotificationType')}>
              <Select onChange={handleSelect}>
                {Object.keys(notificationTypes).map(key => (
                  <Option key={key} value={key}>{notificationTypes[key]}</Option>
                ))}
              </Select>
            </Item>
            <Item
              name="branchId"
              label={f('ActionDeliveries.TH.Branch')}
              rules={[
                { type: 'number', required: true, whitespace: true }
              ]}
            >
              <Select onChange={handleSelect}>
                {map(branches, (branch => (
                  <Option key={branch.id} value={branch.id}>
                    {branch.name}
                  </Option>
                )))}
              </Select>
            </Item>
            <Item
              name="templateName"
              label={f('TemplateEditor.MessageType')}
              rules={[
                { required: true, whitespace: true }
              ]}
            >
              <Select onChange={handleSelect}>
                {map(templateNames, (name => (
                  <Option key={name} value={name}>{name}</Option>
                )))}
              </Select>
            </Item>
          </Col>

          <Col span={12}>
            <Item
              name="senderId"
              label={f('CreateDelivery.Sender')}
              rules={[
                { type: 'number', required: true, whitespace: true }
              ]}
            >
              <Select onChange={handleSelect}>
                {map(senders, (sender => (
                  <Option key={sender.id} value={sender.id}>
                    {sender.name}
                  </Option>
                )))}
              </Select>
            </Item>
            <Item name="orderType" label={f('TemplateEditor.OrderType')}>
              <Select onChange={handleSelect}>
                <Option key={0} value=""></Option>
                {Object.keys(orderTypes).map(key => (
                  <Option key={key} value={key}>{orderTypes[key]}</Option>
                ))}
              </Select>
            </Item>
          </Col>
        </Row>

        <hr />

        <Row justify="end">
          <Preview
            form={form}
            getOwnerJson={getOwnerJson}
            missingFields={missingFields}
            isType={isType}
            locale={locale}
          />
        </Row>

        <Row gutter={24}>
          <Col span={12}>
            {isType('email') &&
              <Item name="email-subject" label={f('TemplateEditor.Subject')}>
                <Input disabled={templateLoading} />
              </Item>
            }

            {isType('email') &&
              <Item name="email-partial" label={f('TemplateEditor.MainText')}>
                <ReactQuill
                  readOnly={templateLoading}
                  style={{ background: theme === 'light' ? 'white' : 'unset' }}
                />
              </Item>
            }

            {isType('sms') &&
              <SMSEditor loading={templateLoading} form={form} />
            }

            <Row justify="end">
              <Item>
                <Button danger style={{ marginRight: 16 }}>
                  <FormattedMessage id="CreateDelivery.Cancel" />
                </Button>

                <Button type="primary" htmlType="submit" loading={templateLoading} disabled={missingFields()}>
                  <FormattedMessage id="CreateSender.Save" />
                </Button>
              </Item>
            </Row>
          </Col>

          <Col span={12}>
            {isType('email') &&
              <Item name="parameter" label={f('TemplateEditor.Header')}>
                <Input disabled={templateLoading} />
              </Item>
            }
            <Parameters
              getOwnerJson={getOwnerJson}
              missingFields={missingFields}
              locale={locale}
              form={form}
            />
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default TemplateEditor
