export const translations = [];
translations['register-on-shelf'] = 'Skráð í hillu';
translations['order-on-shelf-notification'] = 'Tilkynning send';
translations['reception-send-reminder-timer'] = 'Áminning send';
translations['customer-picks-up-from-reception'] = 'Viðskiptavinur sækir í afgreiðslu';
translations['init'] = 'Uppsetning afhendingarferlis';
translations['confluence'] = 'Komið í afhendingarferli';
translations['pick-shelf-or-box'] = 'Afhendingarmáti valinn';
translations['incoming-order'] = 'Sending skráð';
translations['create-order-delivery'] = 'Sending skráð í hólf';
translations['put-order-in-box'] = 'Hólf valið';
translations['order-in-box-notification'] = 'Tilkynning um að pakki sé í hólfi send';
translations['customer-picks-up-from-box'] = 'Viðskiptavinur mun sækja í hólf';
translations['order-placed-in-box'] = 'Sending tilbúin í hólf';
translations['decide-delivery'] = 'Staðfestið að sending fór í hólf';
translations['decide-delivery-method'] = 'Veljið afhendingarmáta';
translations['decide-delivery-method-pre-lockdown'] = 'Veljið afhendingarmáta';
translations['delivered-directly'] = 'Pöntun afhent viðskiptavini beint';
translations['delivery-decision-timeout'] = 'Pöntun skráð sjálfkrafa í hólf eftir 3 mínútur';

translations['on-shelf'] = 'Pöntun í hillu';
translations['shelf-survey'] = 'Sending afhend úr hillu';
translations['customer-picks-up-from-shelf'] = 'Viðskiptavinur sækir í afgreiðslu';
translations['send-shelf-reminder'] = 'Áminning send um pöntun í hillu';
translations['send-box-reminder'] = 'Áminning um pöntun í hólfi send';
translations['exceeded-box-duration'] = 'Pöntun of lengi í hólfi';
translations['return-to-stock'] = 'Pöntun endursendist';
translations['box-survey'] = 'Sending afhent úr hólfi';
translations['put-on-shelf-instead'] = 'Sending komst ekki í hólf';
translations['request-manual-lockdown'] = 'Hólfi læst';
translations['request-cancel-lockdown'] = 'Hætt við hólf';
translations['request-lockdown'] = 'Hólfi læst';
translations['received-lockdown'] = 'Sending ekki aðgengileg';
translations['inform-customer-about-lockdown'] = 'Viðskiptavini tilkynnt um læst hólf';
translations['package-moved-from-box'] = 'Takið pöntun úr hólfi';
translations['package-moved-from-box-v2'] = 'Takið pöntun úr hólfi';
translations['move-package-from-box'] = 'Skráið pöntun úr hólfi í hillu';
translations['package-from-box-assigned-to-shelf'] = 'Pöntun færð úr hólfi í hillu';

translations['customer-identification'] = 'Auðkenning viðskiptavinar'
translations['customer-pickup'] = 'Viðskiptavinur mun sækja í hólf'
translations['send-pickup-code'] = 'Aðgangskóði sendur'

translations['decide-pickup'] = 'Uppsetning afhendingarferlis';
translations['transform-config'] = 'Uppsetning afhendingarferlis';
translations['delivery-order-created'] = 'Sending skráð í hólf';
translations['decide-pickup-location'] = 'Afhendingarmáti valinn';
translations['box-timer-reminder'] = 'Áminning send um pöntun í hólfi';
translations['delete-apex-order'] = 'Hætt við hólf';

translations['order-returned-notification'] = 'Sending afhend sendanda. Tilkynning send á viðskiptavin.';
translations['sender-picks-up-from-reception'] = 'Sendandi sækir í afgreiðslu';
translations['sender-pickup-notification'] = 'Tilkynning um endursendingu send á sendanda';
translations['order-resent-notification'] = 'Tilkynning um endursendingu send á viðskiptavin';
translations['return-to-sender'] = 'Setja sendingu í endursendingarferli';
translations['exceeded-shelf-duration'] = 'Sending of lengi í hillu.';
translations['delivery-cancelled-after-box'] = 'Sending ekki sett í hólfið';
translations['send-staff-reminder'] = 'Áminning um pöntun í hólfi send á starfsfólk';

//happy path for collections
translations['incoming-collection'] = 'Viðtaka skráð';
translations['create-collection'] = 'Skráð í hólf';
translations['reserve-locker'] = 'Hólf tekið frá';
translations['locker-reserved-notification'] = 'Tilkynning um hólf sent á viðskiptavin';
translations['collection-placed-in-box'] = 'Bíð eftir sendingu í hólf';
translations['update-collection'] = 'Sending komin í hólf';
translations['collection-in-box-notification'] = 'Tilkynning send á starfsfólk um sendingu í hólfi';
translations['collection-collected'] = 'Sækið sendingu í hólf';
translations['verify-collection'] = 'Staðfestið viðtöku';
translations['collection-verified-notification'] = 'Tilkynning um viðtöku sendingar send á viðskiptavin';
translations['end-collection-accepted'] = 'Sending viðtekin';
translations['pending-collection'] = 'Sending í bið';
translations['related-shipment-delivered'] = 'Tengd sending afhent';
translations['verify-locker'] = 'Bíð eftir að notandi staðfesti skil';
translations['collection-in-bad-locker-notification'] = 'Skil voru gerð í röngu hólfi';

//reminders

translations['collection-reminder-notification'] = 'Áminning um að skila sendingu í hólf send á viðskiptavin';
translations['staff-collection-reminder'] = 'Áminning um að sækja sendingu í hólf send á starfsfólk';

//collection exceptions
translations['manual-cancelation'] = 'Hætta við viðtöku';
translations['collection-cancelled'] = 'Hætt við sendingu';
translations['unreserve-locker'] = 'Afskráning hólfs';
translations['collection-cancelled-customer-notification'] = 'Tilkynning um að hætt hafi verið við viðtöku send á viðskiptavin';
translations['collection-cancelled-staff-notification'] = 'Tilkynning um að hætt hafi verið við viðtöku send á starfsfólk';
translations['end-collection-rejected'] = 'Viðtöku hafnað';
translations['verify-reserve'] = 'Staðfestið frátekt á hólfi';
translations['end-collection-cancelled'] = 'Viðtöku lokið. Hætt við';

export const blacklistedStatuses = [
    'decide-pickup',
    'transform-config',
    'delivery-order-created',
    'decide-pickup-location',
    'box-timer-reminder',
    'request-lockdown',
    'delete-apex-order',
    'reception-send-reminder-timer',
    'lockdown-notification-timeout',
    'BoundaryEvent_0h6szie',
    'send-lockdown-notification',
    'cancel-lockdown-notification',
    'box-collect-timer',
    'EndEvent_038psog',
    'EndEvent_1qksubt',
    'StartEvent_1ameh8c',
    'fake',
    'customer-check-delivery-notification',
    'related-delivery-check-timer',
    'verify-locker'
];

export const PERMISSION = {
    createRelatedShipmentDeliveryForSender: 'CreateRelatedShipmentDeliveryForSender',
    createCollection: 'CreateCollectionForBranch',
    readCollection: 'ReadCollectionForBranch',

    readDashboard: 'ReadDashboard',

    createSender: 'CreateSender',
    readSender: 'ReadSender',
    editSender: 'EditSender',

    createCompany: 'CreateCompany',
    readCompany: 'ReadCompany',
    editCompany: 'EditCompany',

    createBranch: 'CreateBranch',
    readBranch: 'ReadBranch',
    editBranch: 'EditBranch',

    readTemplate: 'ReadTemplate',
    editTemplate: 'EditTemplate',

    createUser: 'CreateUser',
    adminGroup: 'AdminGroup',
    accessAll: 'AccessAll'
};

export const SHOW_SHELF_BUTTON = process.env.REACT_APP_SHOW_SHELF_BUTTON === "true"

export const AUTH_SERVER_LINK = process.env.REACT_APP_AUTH_SERVER_LINK
