import React, { useState, useCallback, useEffect } from 'react';
import { Input, AutoComplete } from 'antd';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { debounce, isEmpty } from 'lodash';

import { API } from '../../AxiosWrapper';
import { SearchIcon } from '../shared/icons'

const HeaderSearch = () => {
  const intl = useIntl();
  const history = useHistory();
  const f = (id) => intl.formatMessage({ id })

  const [searchString, setSearchString] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [path, setPath] = useState(null);

  const handleSelect = (businessKey) => {
    setPath(`/about-delivery/${businessKey}`)
  }

  const onEnter = (searchString) => {
    setPath(`/search-shipment?searchString=${searchString}`)
  }

  useEffect(() => {
    if (path) {
      //clear the input
      setSearchString("")
      history.push(path)
    }
  }, [path, history])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce(searchString => search(searchString), 300),
    [], // will be created only once initially
  );

  const search = (searchString) => {
    let queryUrl = 'Delivery?limit=8&searchString=' + searchString;
    API.GET(queryUrl)
      .then((response) => {
        setSuggestions(response.data
          .map(d => ({ ...d, senderOrderId: d.senderOrderID, senderOrderID: null })) //TODO fix camelCase on backend
        )
      })
  }


  const handleSearch = (searchString) => {
    setSearchString(searchString);
    if (searchString.length < 2) {
      setSuggestions([]);
      return;
    }
    debouncedSearch(searchString);
  }

  let match = null

  function traverseForMatch(obj) {
    for (let key in obj) {
      const value = obj[key];
      if (typeof value === 'string' && value.toLowerCase().includes(searchString.toLowerCase())) {
        match = { key, value };
        return;
      }
      if (value && typeof value === 'object') {
        traverseForMatch(value);
      }
    }
  }

  const mapSuggestion = (delivery) => {
    traverseForMatch(delivery);
    if (isEmpty(match)) return delivery.recipient.name;

    const re = new RegExp(searchString, "gi");
    const replaced = match.value.replace(re, (match) => "<b>" + match + "</b>");
    return (
      <>
        <i>{match.key}</i> : <span dangerouslySetInnerHTML={{ __html: replaced }} /> | {delivery.recipient.name} | {delivery.description}
      </>
    )
  }

  const children = suggestions.map(delivery => ({
    label: mapSuggestion(delivery),
    value: delivery.businessKey,
  }))

  const onPressEnter = () => {
    if (searchString?.length > 0) {
      onEnter(searchString);
    }
  }

  return (
    <AutoComplete
      style={{ width: '100%' }}
      options={children}
      onSearch={handleSearch}
      onSelect={handleSelect}
      defaultActiveFirstOption={false}
      popupMatchSelectWidth={false}
      value={searchString}
    >
      <Input
        placeholder={f('Header.Search.Placeholder')}
        suffix={<SearchIcon onClick={onPressEnter} />}
        onPressEnter={onPressEnter}
      />
    </AutoComplete>
  );
}

export default HeaderSearch;
