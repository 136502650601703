import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Layout, Row, Col, Typography } from 'antd';

import { API } from '../../AxiosWrapper';
import { useColumns } from '../shared/useColumns';
import DeliveryTable from '../shared/DeliveryTable'
import VisibleInfo from '../shared/VisibleInfo';
import Back from '../shared/Back';
import { useHandleApiError } from '../shared/hooks'
import { useLocation } from 'react-router-dom';

const { Title } = Typography

const baseColumns = [
  "recipient.identificationNumber",
  "businessKey",
  "orderType",
  "createDate",
  "location",
  "lastMessageSent",
  "currentState",
  "sender",
  "senderOrderId",
]

const SearchResults = ({ permissions, allowedColumns = new Map() }) => {
  const [deliveries, setDeliveries] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleApiError = useHandleApiError()
  const columns = useColumns(permissions, allowedColumns, baseColumns)
  const { search } = useLocation()

  useEffect(() => {
    setLoading(true);
    const searchString = new URLSearchParams(search).get('searchString');
    if (!searchString) {
      setLoading(false)
      return
    };

    API.GET('Delivery?searchString=' + searchString)
      .then(response => setDeliveries(response.data
        .map(d => ({ ...d, senderOrderId: d.senderOrderID })).reverse())) //TODO fix camelCase on backend
      .catch(handleApiError)
      .finally(() => setLoading(false))
  }, [search]) // eslint-disable-line react-hooks/exhaustive-deps

  const [orderedColumns, setOrderedColumns] = useState(columns)
  const visibleColumns = orderedColumns.filter(col => col.visible)

  return (
    <Layout.Content>
      <Title level={2}><FormattedMessage id="SearchResults.Title" /></Title>
      <Row>
        <Col span={12}>
          <Back />
        </Col>
        <Col span={12} style={{ display: 'flex', justifyContent: 'end' }}>
          <VisibleInfo
            tableName="SearchResults"
            columns={orderedColumns}
            setColumns={setOrderedColumns}
            loading={loading}
          />
        </Col>
      </Row>

      <DeliveryTable
        columns={visibleColumns}
        dataSource={deliveries}
        loading={loading}
        pagination={false}
      // scrollY={600}
      />
    </Layout.Content>
  );
}

export default SearchResults;
