import React from 'react';
import { Layout } from 'antd';

import ActionableDeliveries from './ActionableDeliveries';

const { Content } = Layout;

const ActionList = ({ branches, branchId, permissions, allowedColumns }) => {
  return (
    <Layout>
      <Content>
        <ActionableDeliveries
          branches={branches}
          branchId={branchId}
          permissions={permissions}
          allowedColumns={allowedColumns}
        />
      </Content>
    </Layout>
  );
}

export default ActionList;
