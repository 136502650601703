import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {
  Row, Col, Layout, Form, Input, Select, Button, Tooltip, Spin, message, AutoComplete, Typography
} from 'antd';

import { API } from '../../AxiosWrapper';
import Back from '../shared/Back';
import { useHandleApiError, useFormatMessage } from '../shared/hooks'
import { phoneValidator } from '../shared/utils';

const { Item } = Form;
const { Option } = Select;
const { Title } = Typography

const CreateUser = ({ permissions }) => {
  const f = useFormatMessage()
  const handleApiError = useHandleApiError()
  const [form] = Form.useForm()
  const history = useHistory()

  const [groupsLoading, setGroupsLoading] = useState(false)
  const [suggestionsLoading, setSuggestionsLoading] = useState(false)
  const [posting, setPosting] = useState(false)
  const [allGroups, setAllGroups] = useState([])
  const [user, setUser] = useState()
  const [suggestions, setSuggestions] = useState([])
  const [autocompleteText, setAutocompleteText] = useState('')

  const userId = new URL(window.location).searchParams.get('id')

  const getGroups = () => {
    setGroupsLoading(true)
    API.GET('admin/group')
      .then(({ data }) => setAllGroups(data))
      .catch(handleApiError)
      .finally(() => setGroupsLoading(false))
  }

  useEffect(getGroups, [permissions]) // eslint-disable-line react-hooks/exhaustive-deps

  const getUser = email => {
    API.GET('admin/group/withUsers')
      .then(({ data }) => {
        const userGroups = data.reduce((acc, group) =>
          group.users.some(u => u.email === email)
            ? [...acc, group.name]
            : acc
          , [])
        setUser({ email, groups: userGroups })
      })
      .catch(handleApiError)
  }

  const getEmails = () => {
    setSuggestionsLoading(true)
    API.GET('admin/group/withUsers')
      .then(({ data }) => {
        const emails = new Set()
        data.forEach(group => group.users.forEach(u => u.email && emails.add(u.email)))
        const options = [...emails].map(email => ({ label: email, value: email }))
        setSuggestions(options)
      })
      .catch(handleApiError)
      .finally(() => setSuggestionsLoading(false))
  }

  useEffect(() => {
    if (userId) getUser(userId)
    else getEmails()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => form.resetFields(), [form, user]); // https://github.com/ant-design/ant-design/issues/22372

  const handleResend = () => {
    const url = `admin/user/${user.email}/group/${user.groups[0]}/resendInvitation`;
    API.POST(url)
      .then(() => message.success(f('Generic.Success')))
      .catch(handleApiError)
  }

  const handleSubmit = ({ email, groups }) => {
    const currentUserGroups = user?.groups ?? []
    const groupsToAdd = groups.filter(g => !currentUserGroups.includes(g))
    const groupsToRemove = currentUserGroups.filter(g => !groups.includes(g))

    const promises1 = groupsToAdd.map(group => API.POST(`admin/user/${email}/group/${group}`))
    const promises2 = groupsToRemove.map(group => API.DELETE(`admin/user/${email}/group/${group}`))

    setPosting(true)

    Promise.all([...promises1, ...promises2])
      .then(() => {
        message.success(f('Generic.Success'))
        history.push('/admin/access-groups')
      })
      .catch(handleApiError)
      .finally(() => setPosting(false))
  }

  const filteredSuggestions = autocompleteText.length
    ? suggestions.filter(obj => obj.value.includes(autocompleteText))
    : []

  if (userId && !user) return <Spin size="large" />

  return (
    <Layout.Content>
      <Row>
        <Col xs={24} lg={12}>
          <Title level={2} style={{ marginBottom: 16 }}>
            <FormattedMessage id={user ? 'CreateUser.EditUser' : 'CreateUser.AddUser'} />
          </Title>
          <Back />

          <Form
            form={form}
            layout="vertical"
            className="form-style"
            onFinish={handleSubmit}
            initialValues={{
              firstName: user?.firstName,
              lastName: user?.lastName,
              email: user?.email,
              phone: user?.phone,
              groups: user?.groups,
            }}
          >
            <Item
              name="firstName"
              rules={[
                { whitespace: true }
              ]}
              label={f('AccessGroups.FirstName')}
              hasFeedback
            >
              <Input maxLength={50} disabled />
            </Item>
            <Item
              name="lastName"
              rules={[
                { whitespace: true }
              ]}
              label={f('AccessGroups.LastName')}
              hasFeedback
            >
              <Input maxLength={50} disabled />
            </Item>
            <Item
              name="email"
              label={f('CreateDelivery.Email')}
              rules={[
                { type: 'email', message: f('Validation.EmailInvalid') },
                { required: true, whitespace: true, message: f('Validation.EmailRequired') }
              ]}
              hasFeedback
            >
              <AutoComplete
                type="email"
                options={filteredSuggestions}
                onSearch={setAutocompleteText}
                loading={suggestionsLoading}
              />
            </Item>
            <Item
              name="phone"
              rules={[
                {
                  validator: phoneValidator, message: f('Validation.PhoneInvalid')
                },
              ]}
              label={
                <Tooltip title={<FormattedMessage id="CreateDelivery.MobileTooltip" />}>
                  <FormattedMessage id="CreateDelivery.Mobile" />
                </Tooltip>
              }
              hasFeedback
            >
              <Input disabled />
            </Item>

            <Item
              name="groups"
              label={f('Admin.Groups')}
              rules={[
                { required: true }
              ]}
              hasFeedback
            >
              <Select mode="multiple" loading={groupsLoading}>
                {allGroups.map(group => (
                  <Option key={group.name} value={group.name}>
                    <Tooltip title={group.description}>
                      {group.name}
                    </Tooltip>
                  </Option>
                ))}
              </Select>
            </Item>

            <Item>
              <Button
                type="primary"
                htmlType="submit"
                shape="round"
                loading={posting}
              >
                <FormattedMessage id="CreateSender.Save" />
              </Button>
            </Item>

            {user &&
              <Button
                type="link"
                style={{ padding: 0, minWidth: 'unset', marginRight: 16 }}
                onClick={handleResend}
              >
                <FormattedMessage id="Admin.Resend" />
              </Button>
            }
          </Form>

        </Col>
      </Row>
    </Layout.Content>
  );
}

export default CreateUser;
