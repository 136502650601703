import React from 'react'
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl'
import { upperFirst } from 'lodash'

import { dateComparison, stringComparison } from '../shared/utils';
import { translations } from '../../Constants';

const dateFormat = 'DD-MM-YYYY HH:mm';

const dataIndexToKey = dataIndex => Array.isArray(dataIndex) ? dataIndex.join(".") : dataIndex

export const columns = [
  {
    dataIndex: 'senderOrderId',
    title: <FormattedMessage id="ActionDeliveries.TH.SenderOrder" />,
    render: (text, record) => (
      <Link to={`/about-delivery/${record.businessKey}`} className="btn-link">
        {text}
      </Link>
    ),
    sorter: (a, b) => stringComparison(a.senderOrderId, b.senderOrderId),
    sortDirections: ['ascend', 'descend'],
    width: 140,
    ellipsis: true,
  },
  {
    dataIndex: ['recipient', 'name'],
    title: <FormattedMessage id="ActionDeliveries.TH.Recipient" />,
    sorter: (a, b) =>
      stringComparison(a.recipient, b.recipient, recipient => recipient.name),
    sortDirections: ['ascend', 'descend'],
    width: "12ch",
    ellipsis: true,
  },
  {
    dataIndex: ['recipient', 'identificationNumber'],
    title: <FormattedMessage id="ActionDeliveries.TH.Kennitala" />,
    sorter: (a, b) =>
      stringComparison(a.recipient, b.recipient, recipient => recipient.identificationNumber),
    sortDirections: ['ascend', 'descend'],
    width: "12ch",
  },
  {
    dataIndex: 'orderType',
    title: <FormattedMessage id="ActionDeliveries.TH.OrderType" />,
    render: orderType => (orderType ? upperFirst(orderType) : ''),
    sorter: (a, b) =>
      stringComparison(a.orderType, b.orderType, orderType => orderType),
    sortDirections: ['ascend', 'descend'],
    width: 120,
  },
  {
    dataIndex: 'branchName',
    title: <FormattedMessage id="ActionDeliveries.TH.Branch" />,
    sorter: (a, b) => stringComparison(a.branchName, b.branchName),
    width: 120,
  },
  {
    dataIndex: 'modifyDate',
    title: <FormattedMessage id="ActionDeliveries.TH.LastChange" />,
    render: (modifyDate) => dayjs(modifyDate).format(dateFormat),
    sorter: (a, b) => dateComparison(a.modifyDate, b.modifyDate),
    sortDirections: ['ascend', 'descend'],
    width: 120,
  },
  {
    dataIndex: 'currentState',
    title: <FormattedMessage id="ActionDeliveries.TH.Status" />,
    render: currentState =>
      translations[currentState.stateId]
        ? translations[currentState.stateId]
        : currentState.name,
    sorter: (a, b) => {
      let translatedA = translations[a.currentState.stateId]
        ? translations[a.currentState.stateId]
        : a.currentState.name;
      let translatedB = translations[b.currentState.stateId]
        ? translations[b.currentState.stateId]
        : b.currentState.name;
      return translatedA.localeCompare(translatedB);
    },
    sortDirections: ['ascend', 'descend'],
    width: 120,
  }
].map(col => ({ ...col, key: col.key ?? dataIndexToKey(col.dataIndex) }));
