import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Layout, Table, Form, Input, Typography, Row, Col } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

import { checkPermission, stringComparison, filterObjectValues } from '../shared/utils';
import { PERMISSION } from '../../Constants';
import { SearchIcon } from '../shared/icons'
import ExportToExcel from '../shared/ExportToExcel'

const { Item } = Form;
const { Title } = Typography

const Senders = ({ senders, permissions }) => {
  const intl = useIntl();
  const f = (id) => intl.formatMessage({ id })

  const [searchString, setSearchString] = useState('');

  const editLink = (item) => (
    <NavLink to={'/admin/create-sender?id=' + item.id} className="btn-link">
      <FormattedMessage id="Admin.Edit" />
    </NavLink>
  );

  const columns = [
    {
      dataIndex: 'name',
      title: <FormattedMessage id="Senders.Name" />,
      sorter: (a, b) => stringComparison(a.name, b.name),
      width: 200,
    },
    {
      dataIndex: 'identificationNumber',
      title: <FormattedMessage id="Senders.IdentificationNumber" />,
      sorter: (a, b) => stringComparison(a.identificationNumber, b.identificationNumber),
      width: 100,
    },
    {
      dataIndex: 'contactEmail',
      title: <FormattedMessage id="Senders.ContactEmail" />,
      sorter: (a, b) => stringComparison(a.contactEmail, b.contactEmail),
      width: 200,
    },
    {
      dataIndex: 'contactPhone',
      title: <FormattedMessage id="Senders.ContactPhone" />,
      sorter: (a, b) => stringComparison(a.contactPhone, b.contactPhone),
      width: 100,
    },
    {
      title: <FormattedMessage id="Table.Actions" />,
      key: 'actions',
      render: (text, item) => checkPermission(permissions, PERMISSION.createSender) ? editLink(item) : '',
      width: 100,
    },
  ];

  const searchableProps = ['name', 'identificationNumber', 'contactEmail', 'contactPhone']

  const handleSearch = (e) => {
    setSearchString(e.target.value)
  }

  const dataSource = senders
    .filter((obj) => filterObjectValues(obj, searchableProps, searchString));

  return (
    <Layout.Content>
      <Title level={2}><FormattedMessage id="Sidebar.Senders" /></Title>

      <Form layout="vertical">
        <Item label={<span><FormattedMessage id="Input.Search" /></span>}>
          <Input
            className="standard-input"
            suffix={<SearchIcon />}
            onChange={handleSearch}
            placeholder={f('Input.SearchList')}
          />
        </Item>
      </Form>

      <Row>
        <Col span={8} >
          {checkPermission(permissions, PERMISSION.editSender) &&
            <NavLink to="/admin/create-sender" style={{ marginBottom: 16 }}>
              + <FormattedMessage id="Sidebar.CreateSender" />
            </NavLink>
          }
        </Col>
        <Col span={8}></Col>
        <Col span={8} style={{ textAlign: 'right' }}>
          <ExportToExcel dataSource={dataSource} columns={columns} />
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={dataSource}
        scroll={{ x: 'max-content' }}
        rowKey={'id'}
        pagination={{
          showTotal: (total, range) => `${range[0]}-${range[1]} ${f('Pagination.Of')} ${total}`,
          showLessItems: true,
          showSizeChanger: true,
        }}
        sticky
      />
    </Layout.Content>
  );
};

export default Senders;
