import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Form, Row, Col, Input } from 'antd';

import { useFormatMessage } from '../shared/hooks'

const { TextArea } = Input;
const { Item } = Form;

// one SMS could contain 160 GSM-7 characters or 70 UTF-16 (also called UCS-2) characters
const smsGSM7Limit = 160
const smsUTF16Limit = 70

const containsNonGSM7Character = text => /[^\x00-\x7F]/.test(text) // eslint-disable-line no-control-regex

const SMSEditor = ({ form, loading }) => {
  const f = useFormatMessage()

  const [textLength, setTextLength] = useState(0)
  const [usingNonEnglishCharacters, setUsingNonEnglishCharacters] = useState(false)

  const characterLimit = (usingNonEnglishCharacters ? smsUTF16Limit : smsGSM7Limit) * 6 // six messages

  const setInitialCounters = () => {
    const sms = form.getFieldValue('sms')
    if (!sms || loading) return

    setTextLength(sms.length)
    setUsingNonEnglishCharacters(containsNonGSM7Character(sms))
  }

  useEffect(setInitialCounters, [form, loading])

  const getNumOfMessages = (textLength, usingNonEnglishCharacters) => usingNonEnglishCharacters
    ? Math.ceil(textLength / smsUTF16Limit)
    : Math.ceil(textLength / smsGSM7Limit)

  const updateCalculations = () => {
    const sms = form.getFieldValue('sms')
    setUsingNonEnglishCharacters(containsNonGSM7Character(sms))
    setTextLength(sms.length)
  }

  return (
    <>
      <Item name="sms" label={f('TemplateEditor.SMS')}>
        <TextArea
          rows={8}
          maxLength={characterLimit}
          disabled={loading}
          onChange={updateCalculations}
        />
      </Item>

      <Row style={{ marginBottom: 16 }}>
        <Col span={12}><FormattedMessage id="TemplateEditor.NumberOfMessages" /> {getNumOfMessages(textLength, usingNonEnglishCharacters)} / 6</Col>
        <Col span={12} style={{ textAlign: 'right' }}>{textLength} / {characterLimit}</Col>
      </Row>
    </>
  )
}

export default SMSEditor