export const labelStyle = {
  marginBottom: 8,
  display: 'block',
  fontSize: 16,
  fontWeight: 'bold'
};

export const cardStyle = {
  // background: 'white',
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  marginBottom: 24,
  padding: 12,
  height: 94,
};

export const cardInfoStyle = {
  // color: '#444',
  textAlign: 'center',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
}

export const cardTextStyle = {
  display: 'block',
  lineHeight: '50px',
}
