import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Typography } from 'antd';

const { Title, Text } = Typography

const ErrorNotFound = () => {
  useEffect(() => {
    const intervalId = setTimeout(() => setRedirect(true), 2000)

    return () => {
      clearTimeout(intervalId)
    }
  }, [])

  const [redirect, setRedirect] = useState(false);

  return redirect
    ? <Redirect to="/" />
    :
    <div id="error">
      <Title level={1}><FormattedMessage id="ErrorNotFound" /></Title>
      <Title level={2}><FormattedMessage id="ErrorNotFound.Description" /></Title>
      <Text><FormattedMessage id="ErrorNotFound.Description2" /></Text>
    </div>
}

export default ErrorNotFound;

