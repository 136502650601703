import React from 'react';
import { Row, Col, Card, Typography, Spin } from 'antd';
import { FormattedMessage } from 'react-intl';

import { labelStyle, cardStyle, cardInfoStyle, cardTextStyle } from './style';
import boxSvg from '../../assets/box.svg';
import checkmarkSvg from '../../assets/checkmark.svg';
import boxCheckedSvg from '../../assets/box-checked.svg';
import boxSvgLight from '../../assets/box-light.svg';
import checkmarkSvgLight from '../../assets/checkmark-light.svg';
import boxCheckedSvgLight from '../../assets/box-checked-light.svg';
import { useTheme } from '../shared/hooks'

const { Text } = Typography;

const LockersCount = ({ generalStats, loading }) => {
  const isDarkMode = useTheme() === 'dark'

  return (
    <Row gutter={24}>
      <Col xs={24} sm={12} md={8}>
        <Text strong style={labelStyle}>
          <FormattedMessage id="Home.UnclaimedShipments" />
        </Text>
        <Card bodyStyle={cardStyle}>
          <img alt="box" src={isDarkMode ? boxSvgLight : boxSvg} />
          <div style={cardInfoStyle}>
            <Text style={{ fontSize: 34, ...cardTextStyle }}>
              {
                loading
                  ? <Spin />
                  : generalStats.unclaimedShipments
              }
            </Text>
            {/* <Text style={{ fontSize: 12 }}>Snjallbox 5</Text> */}
          </div>
        </Card>
      </Col>
      <Col xs={24} sm={12} md={8}>
        <Text strong style={labelStyle}>
          <FormattedMessage id="Home.FreeCompartments" />
        </Text>
        <Card bodyStyle={cardStyle}>
          <img alt="box" src={isDarkMode ? checkmarkSvgLight : checkmarkSvg} />
          <div style={cardInfoStyle}>
            <Text style={{ fontSize: 22, ...cardTextStyle }}>
              {
                loading
                  ? <Spin />
                  : `${generalStats.totalFreeCompartments}/${generalStats.totalCompartments}`
              }
            </Text>
            {/* <Text style={{ fontSize: 12 }}>Snjallbox 49</Text> */}
          </div>
        </Card>
      </Col>
      <Col xs={24} sm={12} md={8}>
        <Text strong style={labelStyle}>
          <FormattedMessage id="Home.DeliveredLastWeek" />
        </Text>
        <Card bodyStyle={cardStyle}>
          <img alt="box" src={isDarkMode ? boxCheckedSvgLight : boxCheckedSvg} />
          <div style={cardInfoStyle}>
            <Text style={{ fontSize: 34, ...cardTextStyle }}>
              {
                loading
                  ? <Spin />
                  : generalStats.deliveredLastWeek
              }
            </Text>
            {/* <Text style={{ fontSize: 12 }}>Snjallbox 27</Text> */}
          </div>
        </Card>
      </Col>
    </Row>
  )
}

export default LockersCount