import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { FormattedMessage } from 'react-intl';
import { Layout, Col, Form, Row, Typography } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/is';
import { get, isEmpty, isEqual } from 'lodash';

import { API } from '../../AxiosWrapper';
import ShippingHistory from './ShippingHistory';
import BottomButtons from './BottomButtons';
import PrintButton from './PrintButton';
import Barcode from 'react-barcode';
import { canPutInBox } from './utils';
import { useHandleApiError, useFormatMessage } from '../shared/hooks'
import ErrorNotFound from '../ErrorNotFound';
import Email from '../shared/delivery/Email'
import Phone from '../shared/delivery/Phone'
import RentalCar from '../shared/delivery/RentalCar'
import InputField from '../shared/delivery/InputField'
import Sender from '../shared/delivery/Sender';

const { Title } = Typography

dayjs.locale('is');

const barcodeLabelOptions = {
  height: 55,
  width: 2,
  marginLeft: 8,
  marginRight: 8,
  marginTop: 0,
  marginBottom: 1,
  displayValue: false,
  format: 'CODE128',
};

//TODO fix translation
const getBranchName = (branches, branchId) => {
  const branch = branches.find(b => b.id === branchId)
  return branch?.name || 'Útibú ekki skilgreint'
}

const valuesFromData = (data, branches) => ({
  senderId: get(data, 'sender.id'),
  email: get(data, 'recipient.email'),
  phone: get(data, 'recipient.phone'),
  kennitala: get(data, 'recipient.identificationNumber'),
  rentalCar: data.rentalCar,
  createDate: data.createDate ? dayjs(data.createDate).format('YYYY-MM-DD HH:mm') : null,
  createdByUserName: data.createdByUserName,
  recipientName: get(data, 'recipient.name'),
  businessKey: data.businessKey,
  street: get(data, 'recipient.street'),
  senderOrderId: data.senderOrderID, //TODO fix camelCase on backend
  description: data.description,
  location: data.location,
  currentState: get(data, 'currentState.name'),
  apexStatus: data.apexStatus,
  branchName: getBranchName(branches, data.deliveryBranchId),
})

const AboutDelivery = ({ branches, senders }) => {
  const [form] = Form.useForm();
  const handleApiError = useHandleApiError()
  const f = useFormatMessage()
  const { businessKey } = useParams()

  const [delivered, setDelivered] = useState(false);
  const [editing, setEditing] = useState(false);
  const [data, setData] = useState({});
  const [notFound, setNotFound] = useState(false);

  // useCallback had been removed because it uses internal caching (not updating data) and creates endless loop
  const getFormData = (shipNum, branches, oldData) => {
    API.GET(`Delivery/${shipNum}`)
      .then(res => {
        if (isEqual(oldData, res.data)) return

        form.setFieldsValue(valuesFromData(res.data, branches))
        setData(res.data)
      })
      .catch(error => {
        if (error.response?.status === 404)
          setNotFound(true)
        handleApiError(error)
      })
  }

  useEffect(() => {
    if (!businessKey || !branches) return

    getFormData(businessKey, branches, data)

    const timerId = setInterval(() => getFormData(businessKey, branches, data), 2000)
    return () => clearInterval(timerId)

  }, [businessKey, branches, data]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (senders.length === 1)
      form.setFieldValue("senderId", senders[0].id);
  }, [senders, form])

  const handlePatch = () => {
    setEditing(false);
    form.validateFields(['phone', 'email', 'senderId'])
      .then((values) => {
        const { senderId, phone, email } = values
        const url = 'Delivery/' + data.businessKey;
        const patchData = [
          {
            path: '/sender/id',
            op: 'replace',
            value: senderId,
          },
        ];
        API.PATCH(url, patchData)
          .then((response) => { })
          .catch(handleApiError);

        const recipientUrl = 'Delivery/' + data.businessKey + '/recipient';
        const recipientData = [
          {
            path: '/phone',
            op: 'replace',
            value: phone,
          },
          {
            path: '/email',
            op: 'replace',
            value: email,
          },
        ];
        API.PATCH(recipientUrl, recipientData)
          .then((response) => { })
          .catch(handleApiError);
      })
  }

  const handleSubmit = (businessKey, command) => {
    let url = 'Delivery/' + (businessKey ? businessKey : '') + '/' + command;

    form.validateFields(
      [
        'createDate', 'businessKey', 'senderId', 'recipientName', 'email', 'phone', 'street', 'senderOrderId', 'description', 'numberOfPackages', 'delivered',
      ])
      .then((values) => {
        const postData = {
          createDate: values.createDate,
          businessKey: values.businessKey,
          senderId: values.senderId,
          recipient: {
            name: values.recipientName,
            email: values.email,
            phone: values.phone,
            street: values.street,
          },
          senderOrderID: values.senderOrderId, //TODO fix camelCase on backend
          description: values.description,
          numberOfPackages: values.numberOfPackages,
          location: values.location,
          delivered: delivered,
        };
        API.POST(url, postData)
          .then((response) => { })
          .catch(handleApiError);

        getFormData(businessKey, branches, data);
      })
  }

  if (notFound) return (<ErrorNotFound />)
  if (isEmpty(data)) return null;

  const stateId = get(data, 'currentState.stateId');

  const title = data?.businessKey
    ? 'AboutDelivery.Title'
    : data.orderType === 'collection'
      ? 'CreateDelivery.CollectionTitle'
      : 'CreateDelivery.DeliveryTitle'

  const validateMessages = {
    required: f("Validation.Default.Required"),
    whitespace: f("CreateDelivery.FieldWhitespaceError"),
  }

  return (
    <Layout.Content>
      <Title level={2}><FormattedMessage id={title} /></Title>
      <Row>
        <Col xs={24} xl={12}>
          <Form
            form={form}
            layout="vertical"
            className="form-style"
            onFinish={handlePatch}
            validateMessages={validateMessages}
          >
            <InputField name="createDate" disabled={editing} readOnly={true} />
            <InputField name="createdByUserName" disabled={editing} readOnly={true} />
            <InputField name="businessKey" disabled={editing} readOnly={true} />
            <Sender
              senders={senders}
              orderType={data.orderType}
              disabled={editing}
            />
            <InputField name="recipientName" disabled={editing} readOnly={true} />
            <InputField name="kennitala" disabled={editing} readOnly={true} />
            <InputField name="street" disabled={editing} readOnly={true} />
            <Email readOnly={!editing} />
            <Phone readOnly={!editing} />
            <InputField name="senderOrderId" disabled={editing} readOnly={true} />
            <InputField name="description" disabled={editing} readOnly={true} />
            <RentalCar disabled={true} />
            <InputField name="currentState" disabled={editing} readOnly={true} />
            <InputField name="apexStatus" disabled={editing} readOnly={true} />
            {/* only show branch name when user has access to multiple branches */}
            {branches.length > 1 &&
              <InputField name="branchName" disabled={editing} readOnly={true} />
            }

            <BottomButtons
              form={form}
              stateId={stateId}
              editing={editing}
              setEditing={setEditing}
              businessKey={data.businessKey}
              handleSubmit={handleSubmit}
              setLocation={(val) => form.setFieldValue('location', val)}
              setDelivered={setDelivered}
            />
          </Form>
        </Col>
        <Col xs={24} xl={10}>
          <ShippingHistory
            data={data}
            delivered={delivered}
          />
          {canPutInBox(stateId) && data.deliveryCode && !delivered
            &&
            <PrintButton>
              {data.recipient?.identificationNumber}
              <div>
                <Barcode
                  className="BarcodeOutBox"
                  {...barcodeLabelOptions}
                  value={data.deliveryCode}
                />
              </div>
            </PrintButton>
          }
        </Col>
      </Row>
    </Layout.Content >
  );
}

export default AboutDelivery;
