import React from 'react';
import { Form, Input } from 'antd';

import BoxSize from '../shared/delivery/BoxSize';
import { useFormatMessage } from '../shared/hooks'

const { Item } = Form;

const Boxes = ({ lockers, returningOld }) => {
  const f = useFormatMessage()

  return (
    <>
      <BoxSize lockers={lockers} />

      {returningOld &&
        <>
          <Item
            name="oldItemDescription"
            rules={[{ required: false, whitespace: true }]}
            label={f('CreateDelivery.OldItemDescription')}
          >
            <Input />
          </Item>

          <BoxSize lockers={lockers} name="oldBoxSize" showButton={false} />
        </>
      }
    </>
  )
}

export default Boxes
